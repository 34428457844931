import * as ActionType from '../ActionType';

export const AuthedUser = (state = {
  isLoading: false,
  authedUser: {}
}, action) => {
  switch(action.type){
    case ActionType.LOGGED_IN:
      localStorage.setItem('token', action.payload.token);
      return {...state, authedUser: action.payload};
    case ActionType.UPDATE_USER:
      if (!action.payload.token){
        action.payload.token = localStorage.getItem('token');
      }
      return {...state, authedUser: action.payload};
    case ActionType.LOGGED_OUT:
      localStorage.removeItem('token');
      return {...state, authedUser: {}};
    default:
      return state;
  }
}