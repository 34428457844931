import React,{useEffect,useState} from 'react';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, AreaChart, XAxis, YAxis, CartesianGrid, Area, Tooltip } from 'recharts';
import profile from "../../images/profile.jpg";

import { RightSidebar } from '../global/rightSidebar/rightSidebar';
import "./copytrade.css"

import profitIco from "../../images/profitIco.svg"
import lossIco from "../../images/lossIco.svg"
import btc from "../../images/btc.svg";
import Waqar_Zaka from "../../images/Waqar_Zaka.png";
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../redux/ActionCreators';
import { ConditionModal } from '../conditionModal';
import close from "../../images/close.svg";
import success from "../../images/success.svg";
import traderBorder from "../../images/traderBorder.png";
import bellIco from "../../images/bellIco.svg";
import { toast } from 'react-toastify';

function CopyTrader({ master, setTraderDetails }) {
  const authedUser = useSelector(state => state.authedUser);
  const dispatch = useDispatch();
  const [showTCModal, setShowTCModal] = useState(false);
  const [showPostFollowModal, setPostFollowModal] = useState(false);
  const [totalProfit, setTotalP] = useState(0);
  const [totalLoss, setTotalL] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);

  const followCheck = () => {
    if (!!authedUser.authedUser.following){
      follow();
    }
    else{
      setShowTCModal(true);
    }
  }
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/get-total-profit-loss/${master?._id}`)
    .then(r => r.json()).then(r => {
      if (r.success){
        setTotalP(r.totalP);
        setTotalL(r.totalL);
      }
    })
    fetch(`${process.env.REACT_APP_BASE_URL}/users/get-total-volume/${master?._id}`)
    .then(r => r.json()).then(r => {
      if (r.success){
        setTotalVolume(r.volume);
      }
    })
  }, []);


  const follow = async () => {
    if (Object.keys(authedUser.authedUser)){
        if (!authedUser.authedUser.master){
          fetch(`${process.env.REACT_APP_BASE_URL}/users/follow`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ master: master._id })
          }).then(r => r.json())
          .then(res => {
              if (res.success){
                  dispatch(updateUser(res.user));
                  setTraderDetails(res.master);
                  if (res.user?.following){
                      setPostFollowModal(true);
                  }
              }
          });
        }
        else{
            toast("Master cannot follow others", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
        }
    }
    else{
        toast('You are not logged in', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
    }
  }
  return(
    <>
      {/* Begin Page Content */}
      {/* <div className="container-fluid"> */}
            {/* Content Row */}
            {/* <div className="row"> */}
              <div className="col-xl-4 col-lg-6 col-md-12 mb-4" >
                <div className="copyTradePage px-0">
                    <div className="balanceMain row">
                        <div className="col-md-12">
                            <div className="followProfileCard themeCardGlobal">
                              {/* <div className="borderCutLeft">
                                <img src={traderBorder} alt="" />
                              </div> */}
                                <div className="r1">
                                    <div className="pMain">
                                        <div className="imgDiv">
                                            <img src={master?.profilepic ? `${process.env.REACT_APP_BASE_URL}/${master?.profilepic}` : profile} alt="" />
                                        </div>
                                        <div>
                                            <div className="name">{master?.username}</div>
                                            <div className="followers" style={{fontWeight: '700'}}>{master?.followers} Followers</div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <Link to={`/followtrader/${master._id}`} className="themeBtn mt-2">Details</Link>
                                      <button onClick={followCheck} className="themeBtn mt-2">
                                        <img className='mr-2' src={bellIco} alt="bellIco" />
                                        {authedUser?.authedUser?.following === master?._id ? 'Unfollow' : 'Follow'}
                                      </button>
                                    </div>
                                </div>
                                <div className="chartRow">
                                    <div className="chatM">
                                        <div className="perM">
                                            <div className="perc">+{totalProfit.toFixed(2)}$</div>
                                            <p className='fs-12'>Total Profit</p>
                                        </div>
                                        <LineChart />
                                    </div>
                                    <div className="BalanceMain">
                                        <div className='balanceM'>
                                            <div className="balance">${totalProfit.toFixed(2)}</div>
                                            <div className="source">Total Profit</div>
                                        </div>
                                        <div className='balanceM'>
                                            <div className="balance">${totalLoss.toFixed(2)}</div>
                                            <div className="source">Total Loss</div>
                                        </div>
                                        <div className='balanceM'>
                                            <div className="balance">${totalVolume.toFixed(2)}</div>
                                            <div className="source">Total Volume</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
              </div>
              
              {/* diver status */}
              {/* <div className="col-xl-3 col-lg-4 mb-4 pr-0">
                  <RightSidebar />
              </div> */}
          {/* </div>
      </div> */}
      <ConditionModal show={showTCModal} agreeFunc={() => {follow();setShowTCModal();}} handleClose={() => {setShowTCModal(false);}} />
      <Modal  show={showPostFollowModal} onHide={() => setPostFollowModal(false)} centered>
        <Modal.Body>
          <div className="connectModal OrderCompelteModal">
            <img className='closeIco' src={close} alt="" onClick={() => setPostFollowModal(false)} />
            <img className='OrderCompelteImg' src={success} alt="" />
            <h2>Followed Succesfully</h2>
            <p>Please allow some currencies as well, else no trade by this master will be copied</p>
            <div className="continueBtn text-center">
              <button onClick={() => setPostFollowModal(false)} className="themeBtn mt-4" >Confirm</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

function LineChart() {
    const data = [
        {
          "name": "Page A",
          "uv": 4000,
          "pv": 2400,
          "amt": 2400
        },
        {
          "name": "Page B",
          "uv": 3000,
          "pv": 1398,
          "amt": 2210
        },
        {
          "name": "Page C",
          "uv": 2000,
          "pv": 9800,
          "amt": 2290
        },
        {
          "name": "Page D",
          "uv": 2780,
          "pv": 3908,
          "amt": 2000
        },
        {
          "name": "Page E",
          "uv": 1890,
          "pv": 4800,
          "amt": 2181
        },
        {
          "name": "Page F",
          "uv": 2390,
          "pv": 3800,
          "amt": 2500
        },
        {
          "name": "Page G",
          "uv": 3490,
          "pv": 4300,
          "amt": 2100
        }
      ]

      
    return(
        <ResponsiveContainer width={200} height={100}>
            <AreaChart width={"100%"}  data={data}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#111" stopOpacity={1}/>
                    <stop offset="95%" stopColor="#67E1AE" stopOpacity={1}/>
                    </linearGradient>
                </defs>
                {/* <XAxis dataKey="name" /> */}
                {/* <YAxis /> */}
                {/* <CartesianGrid  strokeDasharray="3 3" /> */}
                <Tooltip />
                <Area type="monotone" dataKey="pv" stroke="#67E1AE" fillOpacity={1} fill="url(#colorPv)" />
            </AreaChart>
        </ResponsiveContainer>
    )
}
export {CopyTrader};
