import * as ActionType from '../ActionType';

export const Balances = (state = {
  isLoading: false,
  balances: [],
  total: 0,
  connectedExchanges: []
}, action) => {
  switch(action.type){
    case ActionType.SET_BALANCES:
      return {...state, balances: action.payload};
    case ActionType.SET_USD_BALANCE:
      return {...state, total: action.payload};
    case ActionType.SET_CONNECTED_EXCHANGE:
      return {...state, connectedExchanges: action.payload};
    case ActionType.LOGGED_OUT:
      return {...state, balances: [], total: 0, connectedExchanges: []};
    default:
      return state;
  }
}