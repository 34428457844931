import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import btc from "../../../images/btc.svg";
import usdt from "../../../images/usdt.svg";
import ETH from "../../../images/eth.webp";
import usd from "../../../images/usd.svg";
import dollarLogo from "../../../images/dollarLogo.svg";
import { useSelector } from 'react-redux';
import { showBalance } from '../../../helpers';

export const Balancebar = () => {
  const balances = useSelector(state => state.balances);
  const authedUser = useSelector(state => state.authedUser);
  const [ethBalance, setEthBalance] = useState(0);
  const [USDBalance, setUSDBalance] = useState(0);
  const [BTCBalance, setBTCBalance] = useState(0);
  useEffect(() => {
    balances.balances.forEach(b => {
      if (b.asset === 'USDT'){
        setUSDBalance(parseFloat(b.free).toFixed(2).toString());
      }
      if (b.asset === 'ETH'){
        setEthBalance(parseFloat(b.free).toFixed(2).toString());        
      }
      if (b.asset === 'BTC'){
        setBTCBalance(parseFloat(b.free).toFixed(2).toString());
      }
    })
  }, [balances.balances]);

  return (
    <>
    <div className="row">
        <div className="col-xl-9 col-lg-8">
            <div className="balanceBar b1">
                
                <Link to='assets' className="balanceBtn">
                    <div className="innerBtn">
                        <img src={btc} alt="btc" />
                        <span>{showBalance(BTCBalance, authedUser.authedUser.balanceHide)}</span>
                    </div>
                </Link>
                <Link to='assets' className="balanceBtn">
                    <div className="innerBtn">
                        <img src={usdt} alt="btc" />
                        <span>{showBalance(USDBalance, authedUser.authedUser.balanceHide)}</span>
                    </div>
                </Link>
                <Link to='assets' className="balanceBtn">
                    <div className="innerBtn">
                        <img src={ETH} alt="btc" />
                        <span>{showBalance(ethBalance, authedUser.authedUser.balanceHide)}</span>
                    </div>
                </Link>
                <Link to='assets' className="balanceBtn">
                    <div className="innerBtn">
                        <img src={dollarLogo} alt="btc" />
                        <span>{(showBalance(balances.total.toFixed(2), authedUser.authedUser.balanceHide))}</span>
                    </div>
                </Link>
            </div>
        </div>
    </div>
    </>
  )
}


  
