import React,{useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";

import rightArrow from "../../images/right-arrow.svg";
import profile from "../../images/profile.jpg";
import edit from "../../images/edit.svg";
import Delete from "../../images/delete.svg";
import plus from "../../images/plus.svg";
import { driverData } from "./driversData";


function Drivers({match}) {
  const [selectedFile, setSelectedFile] = useState(null);

    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid">
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Companies (124)</h4>
                                 <a href="#" className="driver-row-link">All</a>
                                 <a href="#" className="driver-row-link">Active (86)</a>
                                 <a href="#" className="driver-row-link">In-Active (32)</a>
                             </div>
                             <div className="col-xl-6 col-md-5 jc-fe d-flex">
                                <div class="dropdown dib">
                                    <a class="btn dropdown-toggle border-white bg-white color-dark font-weight-bold fs-12" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Start Date
                                    </a>
                                    <div class="dropdown-menu zi-1" aria-labelledby="dropdownMenu2">
                                        <button class="dropdown-item" type="button">Action</button>
                                        <button class="dropdown-item" type="button">Another action</button>
                                        <button class="dropdown-item" type="button">Something else here</button>
                                    </div>
                                </div>
                                <label className="ml-2 btn border-transparent bg-white color-dark font-weight-bold fs-12">+ Upload File
                                  <input type="file" value="" onChange={(e) => setSelectedFile(e.target.files[0])} />
                                </label> 
                             </div>
                         </div>
                         {/* table */}
                         <Table />
                         <NavLink to="/assignNewLoad" className="diverAddBtn"><img src={plus} alt="" /></NavLink>

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}
         
      </>
    )
  }
  const Table=()=>{
    const [data,setData]=useState(driverData)

    return(
      <div className="card sortby-box br-10 border-transparent mt-3"> 
        <div className="card-header br-10 table-responsive  bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
          <table class="table table-hover drivers-table">
            <thead>
              <tr>
                <th scope="col" className="text-uppercase">Comapany Name</th>
                <th scope="col" className="text-uppercase">Address</th>
                <th scope="col" className="text-uppercase">Phone</th>
                <th scope="col" className="text-uppercase">Consumed </th>
                <th scope="col" className="text-uppercase">Total Limit</th>
                <th scope="col" className="text-uppercase">Temp Limit</th>
                <th scope="col" className="text-uppercase">Temp Limit Consumed</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((row,i)=>(

              <tr>
                <td scope="row" className="d-flex">
                  <div>
                      <img className="img-profile rounded-circle" src={row.profile} />
                  </div>
                      <section>
                        <div className="table-text-dark">{row.driverName}</div>
                        <div className="table-text-light">{row.driverEmail}</div>
                      </section>
                </td>
                <td>
                  <span className="table-text-dark w-max-c db">{row.companyName}</span>
                </td>
                <td className="">
                  <span className="table-text-dark ">{row.loadMove}</span>
                </td>
                <td className="">
                  <span className="table-text-dark ">{row.loadMove}</span>
                </td>
                <td className="">
                  <span className="table-text-dark ">{row.loadMove}</span>
                </td>
                <td className="">
                  <span className="table-text-dark ">{row.loadMove}</span>
                </td>
                <td className="">
                  <span className="table-text-dark ">{row.loadMove}</span>
                </td>
                <td>
                  {/* <span className="status-loaded ">{row.status}</span> */}
                  <span className=
                  {row.status == "active" && row.status != "inactive" 
                  ? "status-loaded driver-status-title w-max-c"
                  :"status-waiting driver-status-title w-max-c"
                  }>
                  {row.status}</span>

                </td>
                <td>
                  <div className="mr-5">
                    <a href="#" className="mr-3"><img src={edit} alt="edit" /></a>
                    <a href="#"><img src={Delete} alt="Delete" /></a>
                  </div>
                
                </td>
              </tr>
              ))}
            
          
            </tbody>
          </table>
      </div>

    </div>
    )
  }

  export default Drivers