export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGGED_FAILED = 'LOGGED_FAILED';

export const UPDATE_USER = 'UPDATE_USER';

export const SET_BALANCES = 'SET_BALANCES';
export const SET_USD_BALANCE = 'SET_USD_BALANCE';

export const SET_ORDERS = 'SET_ORDERS';
export const SET_CONNECTED_EXCHANGE = 'SET_CONNECTED_EXCHANGE';
export const SET_LOADER = 'SET_LOADER';