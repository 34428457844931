import React,{useEffect,useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {LineChart,Line,XAxis,YAxis,CartesianGrid,Tooltip,Legend,ReferenceLine,ResponsiveContainer,} from 'recharts';


import connectImg from "../../images/webp/connectImg.webp";
import modalBorder from "../../images/webp/modalBorder.webp";
import kuCoin from "../../images/kuCoin.svg";
import bitget from "../../images/bitget.svg";
import okx from "../../images/okx.svg";
import binance from "../../images/binanceIco.svg";
import binanceYellow from "../../images/binanceYellow.svg";
import close from "../../images/close.svg";

import giftImg from "../../images/giftImg.png";
import tick from "../../images/tick.svg";
import refresh from "../../images/refresh.svg";
import warning from "../../images/warning.svg";
import binanceLogo from "../../images/binanceLogo.png";
import connectBorder from "../../images/webp/connectBorder.webp";
import connectBtn from "../../images/connectBtn.svg";
import updateApiBtn from "../../images/updateApiBtn.svg";
import rightCutBtn from "../../images/rightCutBtn.svg";
import borderCutRightPink from "../../images/borderCutRightPink.png";
import { RightSidebar } from '../global/rightSidebar/rightSidebar';
import { setBalances, setConnectedExchange, setTotalUSDBalance, setLoaderAction, updateUser } from '../../redux/ActionCreators';
import { Balancebar } from '../global/balanceBar/balanceBar';
import { Loader } from '../loader/loader';
import { toast } from 'react-toastify';
import { KycModal } from './kycModal';
import { PaymentFormComp } from './paymentForm';
import AdminPanel from './adminPanel';


function Dashboard(props) {
  const [show, setShow] = useState(false);
  const [showApiModal, setShowApiModal] = useState(false);
  const [Page, setPage] = useState(1);
  const [usrs, setUsers] = useState([]);
  const [selectedExchange, setSelectedExchange] = useState('');
  const [selectedTrading, setSelectedTrading] = useState('');
  const [kycForm, setKYCForm] = useState(false);
  const [kycFormAdmin, setKYCFormAdmin] = useState(false);
  const [kycFile, setKycFile] = useState('');
  const [kycFaceFile, setKycFaceFile] = useState('');
  const [kycUserAdmin, setKycUserAdmin] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({});

  const openKYCForm = () => setKYCForm(true);
  const closeKYCForm = () => setKYCForm(false);

  const openKYCFormAdmin = () => setKYCFormAdmin(true);
  const closeKYCFormAdmin = () => {
    setKycUserAdmin('')
    setKYCFormAdmin(false);
  };

  const history = useHistory();

  const authedUser = useSelector(state => state.authedUser);
  const balances = useSelector(state => state.balances);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitKYC = () => {
    if (kycFile){
      let name = document.getElementById('kyc-name').value;
      let idType = document.getElementById('kyc-id-type').value;
      // let country = document.getElementById('kyc-country').value;
      if (!name){
        toast('Name is required', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
      if (!idType){
        toast('ID Type is required', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
      else if (!selectedCountry.value || !selectedCountry.label){
        toast('Country is required', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
      else{
        let formdata = new FormData();
        formdata.append('name', name);
        formdata.append('country', JSON.stringify(selectedCountry));
        formdata.append('idType', idType);
        formdata.append('kycfile', kycFile);
        formdata.append('kycfacefile', kycFaceFile);
        fetch(`${process.env.REACT_APP_BASE_URL}/users/kyc-filled`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: formdata
        }).then(r => r.ok ? r.json() : r.text())
        .then(res => {
          if (res.success){
            toast('Submitted', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            dispatch(updateUser({...authedUser.authedUser, kycName: name, kycCountry: JSON.stringify(selectedCountry), kycFile: res.filepath}));
            closeKYCForm();
          }
          else{
            toast(res.msg ? res.msg : 'Failed. Allowed formats: pdf, jpeg, jpg, png', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
          }
        });
      }
    }
    else{
      toast('File is required', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  }

  const kycFileChange = (e) => {
    if (e.target.files.length > 0){
      setKycFile(e.target.files[0]);
    }
  }
  const kycFaceFileChange = (e) => {
    if (e.target.files.length > 0){
      setKycFaceFile(e.target.files[0]);
    }
  }

  const handleCloseAPI = () => {
    setShowApiModal(false);
    document.getElementById('update-api-key').value = '';
    document.getElementById('update-api-key-secret').value = '';
    document.getElementById('update-api-key-name').value = '';
  };
  const handleShowAPI = () => setShowApiModal(true);

  const getUsersToApprove = (jwt, alertt=false) => {
    fetch(process.env.REACT_APP_BASE_URL+"/users/all", {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${jwt}`
      }
    }).then(r => {
      if (r.ok){
        return r.json();
      }
      else{
        return {success: false, msg: 'Failed'};
      }
    }).then(res => {
      if (res.success){
        setUsers(res.users);
        if (alertt){
          toast('Updated users', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
        }
      }
      else{
        toast(res.msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
    })
  }

  useEffect(() => {
    if (authedUser.authedUser?.admin){
      getUsersToApprove(localStorage.getItem('token'));
    }
  }, [authedUser.authedUser]);

  const continueToExchange = () => {
    if (selectedExchange && selectedTrading){
      let a = balances.connectedExchanges.some(ex => ex.exchange === selectedExchange);
      if (!a){
        handleClose();
        setPage(2);  
      }
      else{
        toast('Already connected to this exchange', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
    }
    else{
      toast('Please select trading & exchange', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      });
    }
  } 

  const dispatch = useDispatch()

  const validateApikey = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/api-key/validate`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(r => r.ok ? r.json() : {success:false})
    .then(res => {
      if (res.success){
        toast(`Your api key is valid`, {theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT});
      }
      else{
        toast(res.msg ? res.msg : 'Failed to validate', {theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT});
      }
    })
  }

  const continueToExchangeData = () => {
    try {   
      dispatch(setLoaderAction(true));
      // setPage(3);
      let name = document.getElementById('exch-name').value;
      let key = document.getElementById('exch-key').value;
      let secret = document.getElementById('exch-secret').value;
      let passphrase = '';
      if (selectedExchange === 'okex' || selectedExchange === 'bitget'){
        passphrase = document.getElementById('exch-passphrase').value;
      }
      fetch(process.env.REACT_APP_BASE_URL+"/users/api-keys", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({
          key,
          secret,
          name,
          passphrase,
          exchange: selectedExchange
        })
      }).then(r => r.json()).then(r => {
        dispatch(setLoaderAction(false));
        if (r.success){
          props.validateJWT(localStorage.getItem('token'))
          dispatch(setBalances(r.balances));
          dispatch(setTotalUSDBalance(r.total));
          dispatch(setLoaderAction(false));
          history.push("/portfolio");  
        }
        else{
          toast(r?.msg ? r?.msg : "Invalid API Credentials", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });    
        }
      })
    } catch (error) {
      console.log(error);
      dispatch(setLoaderAction(false));
      toast("Invalid API Credentials", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark'
      })
    }
  } 

  const deleteApiKey = (_id) => {
    dispatch(setLoaderAction(true));
    fetch(`${process.env.REACT_APP_BASE_URL}/users/api-keys`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({apiKeyId: _id})
    }).then(r => r.json())
    .then(r => {
      dispatch(setLoaderAction(false));
      if (r.success){
        dispatch(setConnectedExchange(balances.connectedExchanges.filter(c => c._id !== _id)));
      }
    })
  };
  
  const updateApiKey = (_id) => {
    
    let apiKey = document.getElementById('update-api-key').value;
    let apiKeySecret = document.getElementById('update-api-key-secret').value;
    let apiKeyName = document.getElementById('update-api-key-name').value;
    dispatch(setLoaderAction(true));
    fetch(`${process.env.REACT_APP_BASE_URL}/users/api-keys`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        apiKeyId: _id,
        apiKey,
        apiKeyName,
        apiKeySecret
      })
    }).then(r => r.json())
    .then(r => {
      if (r.success){
        dispatch(setConnectedExchange(balances.connectedExchanges.map(c => {
          if (c._id !== _id){
            return c;
          }
          else{
            return r.connectedExchange
          }
        })));
        dispatch(setLoaderAction(false));
        toast('Success', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
        handleCloseAPI();
      }
    })
  };
  const verifyUserKYC = (id, status) => {
    if (authedUser?.authedUser?.admin){
      fetch(process.env.REACT_APP_BASE_URL+"/users/update-kyc-status", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ userId: id, approved: status })
      }).then(r => r.json())
      .then(res => {
        if (res.success){
          setUsers(usrs.map(u => {
            if (u._id === id){
              return res.user;
            }
            else{
              return u;
            }
          }));
          toast('Success', {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'dark'
          });
          closeKYCFormAdmin();
        }
      })
    }
  }
  const approveUser = (id, status) => {
    if (authedUser?.authedUser?.admin){
      fetch(process.env.REACT_APP_BASE_URL+"/users/update-status", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ userId: id, approved: status })
      }).then(r => r.json())
      .then(res => {
        if (res.success){
          setUsers(usrs.map(u => {
            if (u._id === id){
              return res.user;
            }
            else{
              return u;
            }
          }))
        }
      })
    }
  }
  const copyIP = () => {
    navigator.clipboard.writeText("18.142.153.81");
    toast("Copied", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: 'dark'
    })
  }
  return(
    <>
      {/* Begin Page Content */}
      <div className="container-fluid" >
            {/* Content Row */}
            {/* <Balancebar /> */}
            <div className="row mx-0">
              <div className={(Page == 4 || authedUser?.authedUser?.admin)?"col-12":"col-xl-9 col-lg-8 mb-4"} >
                <div className="dashboardMain">
                  {authedUser.authedUser.admin ? (
                    <AdminPanel usrs={usrs} getUsers={getUsersToApprove} setKycUserAdmin={setKycUserAdmin} openKYCFormAdmin={openKYCFormAdmin} approveUser={approveUser} />
                  ) : (<>
                  {Page == 1 && 
                  <>
                    {/* <div className="borderCutLeft">
                      <img src={connectBorder} alt="" />
                    </div> */}
                    { authedUser.authedUser.kycVerified ? (authedUser.authedUser.approved ? 
                    <>
                    {(!authedUser?.authedUser?.master  && !authedUser?.authedUser?.paid) ? (
                      <PaymentFormComp />
                    ) : (
                      <div className="connectExMain row">
                        <div className="col1 col-xl-7">
                          {balances.connectedExchanges.length > 0 ?
                            <h1 className="mt-2">You are connected to your <span style={{textTransform: 'capitalize'}}>{balances.connectedExchanges[0].exchange}</span> account</h1>
                            :
                            <h1>Connect an exchange to start Trading</h1>
                          }
                          <p>Connect to one of your favorite exchange and use your trading skills to get followers along with profit.</p>
                          {/*  */}
                          {balances.connectedExchanges.length == 0 &&
                          (
                            <button className="themeBtnHome" onClick={()=>handleShow()}>Connect a New Account</button>
                          )}
                        </div>
                        <div className="col2 col-xl-5 px-lg-0">
                          <div className="imgDiv">
                            <img src={connectImg} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    )}
                    </> 
                    : 
                    <div className="connectExMain row">
                      <div className="col1 ">
                        <h1>Dear {authedUser.authedUser.master?"Master Trader,":"Trader,"} </h1>
                        <p>We would like to express our gratitude for your interest in joining Tenup Copy Trading as a Master Trader. We understand your eagerness to begin trading on our platform, but please be advised that the approval process may take up to 3 to 4 business days.</p>
                        <p>In the event that you are waiting for more than a week to receive your approval, we encourage you to contact our dedicated support team at <a className='themeColor' href="mailto:support@copytrade.tenup.io">support@copytrade.tenup.io.</a> Our team is available 24/7 to provide you with prompt assistance and address any queries or concerns you may have.</p>
                        <p>Once again, we appreciate your interest in joining Tenup Copy Trading as a Master Trader and we are confident that you will find great success among our community of traders.</p>
                        <p>Best regards,</p>
                        <p>The Tenup Copy Trading Team.</p>
                      </div>
                      </div>) : (
                      <div className="connectExMain row">
                        <div className="col1 ">
                          <h1>Dear {authedUser.authedUser.master?"Master Trader,":"Trader,"} </h1>
                          {authedUser?.authedUser?.kycName ? (
                            <>
                            <p>We would like to express our gratitude for your interest in joining Tenup Copy Trading as a Master Trader. We understand your eagerness to begin trading on our platform, but please be advised that the approval process may take up to 3 to 4 business days.</p>
                            <p>In the event that you are waiting for more than a week to receive your approval, we encourage you to contact our dedicated support team at <a className='themeColor' href="mailto:support@copytrade.tenup.io">support@copytrade.tenup.io.</a> Our team is available 24/7 to provide you with prompt assistance and address any queries or concerns you may have.</p>
                            <p>Once again, we appreciate your interest in joining Tenup Copy Trading as a Master Trader and we are confident that you will find great success among our community of traders.</p>
                            </>                            
                          ) : (
                            <p>In order to start using this platform, you need to verify yourself by filling out the KYC form. Please click on the button below</p>
                          )}
                          <p>Best regards,</p>
                          <p>The Tenup Copy Trading Team.</p>
                          {authedUser?.authedUser?.kycName ? <></> : <button className='themeBtnHome' onClick={openKYCForm}>KYC FORM</button>}
                        </div>
                      </div>
                      )
                    }
                  {balances.connectedExchanges.length > 0 ? (
                    <div className="row mt-4">
                      <h4 className="mx-2 col-12">Connected Exchanges</h4>

                      <div className="apiUpdateMAin">
                        {/* <div className="borderCutLeft">
                          <img src={borderCutRightPink} alt="" />
                        </div> */}
                        <div className="row1">
                          <div className="profileM">
                            <div className="logoDiv">
                              {balances.connectedExchanges[0].exchange === 'binance' && <img src={binanceLogo} alt="" /> }
                              {balances.connectedExchanges[0].exchange === 'okex' && <img src={okx} alt="" style={{height: '40px'}} />}
                              {balances.connectedExchanges[0].exchange === 'bitget' && <img src={bitget} alt="" style={{height: '40px'}} />}
                            </div>
                            <div className="name">My <span style={{textTransform: 'capitalize'}}>{balances.connectedExchanges[0].exchange}</span></div>
                          </div>
                          <div className="refresh" onClick={validateApikey}><img src={refresh} alt="" /></div>
                        </div>
                        <div className="py-3">
                          <div className="hrLine"></div>
                        </div>
                        <div className="row2">
                          {/* <img src={warning} alt="" className="warning" /> */}
                          {/* <div className="text">Api Key is valid</div> */}
                          {/* <div className="msg">Please update API key</div> */}
                        </div>
                        <div className="btnMain">
                          {/* <div className="btnRightCut w-100" onClick={handleShowAPI}>
                            <img className='' src={rightCutBtn} alt=""  />
                            <span>Update API KEY</span>
                          </div>
                          <div className="btnRightCut w-100" onClick={() => deleteApiKey(balances.connectedExchanges[0]._id)}>
                            <img className='' src={rightCutBtn} alt=""  />
                            <span>Delete</span>
                          </div> */}
                          <button className="themeBtnHome" onClick={handleShowAPI}>Update Api Key</button>
                          <button onClick={() => deleteApiKey(balances.connectedExchanges[0]._id)} className="themeBtnHome ml-3">Delete</button>
                        </div>
                      </div>
                    </div>
                 ) : (<></>)}  
                  </>
                  }
                  {Page === 2 ?
                    (<div className="connectExPage">
                      <div className="head">
                        <h2>How to Generate and Add 
                        {(selectedExchange === 'okex') ? ' Okex ' : ''} 
                        {(selectedExchange === 'binance') ? ' Binance ' : ''} 
                        {(selectedExchange === 'bitget') ? ' Bitget ' : ''} 
                          API Keys?</h2>
                      </div>
                      {(selectedExchange === 'binance') ?  
                        <div className="mt-3 connectExMain row" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                          <p>
                            1. Log in to your Binance account. Go to Profile {"-->"} API Management page and click the "Create API" button.
                          </p>
                          <p>
                            2. Select the “System generated API key” option. Enter your API key label and pass the Security verification.
                          </p>
                          <p style={{color: '#67E1AE', }}>
                            Be advised: Do not close or reload the page, otherwise the Secret Key will be hidden once and forever.
                          </p>
                          <p>
                            3. Hit the ‘Edit restriction' button and select the following checkboxes:
                          </p>
                          <p>For Spot trading:</p>
                          <ul style={{fontWeight: 'bold'}}>
                          <li>Enable Spot & Margin Trading</li>
                          </ul>
                          <p>
                            For Spot and Futures trading:
                          </p>
                          <ul style={{fontWeight: 'bold'}}>
                          <li>
                          Enable Futures
                          </li>
                          <li>
                          Enable Spot & Margin Trading
                          </li>
                          <li>
                          Permits Universal Transfer
                          </li>
                          </ul>
                          <p>
                          4. Switch the ‘Restrict access to trusted IPs only’ radio button, return to Coinmatics website tab and click the “Copy IP” button, enter them to the field on Binance and click the “Confirm” button.
                          </p>
                          <div style={{
                            color: '#67E1AE',
                            border: '1px solid',
                            borderRadius: '10px',
                            padding: '15px 20px',
                            cursor: 'pointer'
                          }} onClick={copyIP}>COPY OUR IP</div>
                          <p style={{marginTop: '10px', fontWeight: 'bold'}}>
                          Pay attention: You should copy and enter the IP-addresses without any changes. If you do change, API Key will not work properly
                          </p>
                          <div className="text-center mt-4">
                            <button className="themeBtnHome" onClick={() => setPage(3)}>Continue</button>
                          </div>
                        </div>
                      : <></>}

                      {(selectedExchange === 'okex') ?  
                        <div className="mt-3 connectExMain row" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                          <p>
                          To generate API keys in the OKX crypto exchange, follow these steps:
                          </p>
                          <p>
                          1. Log In to OKX: <br/>
                          - Visit the OKX website and log in to your account.
                          </p>

                          <p>
                            2. Navigate to API Management: <br/>
                            - Once logged in, go to your account dashboard. <br/>
                            - Click on your profile icon or user menu at the top right corner. <br/>
                            - Select "API" from the dropdown menu. This will take you to the API management page.
                          </p>

                          <p>
                            3. Create a New API Key: <br/>
                            - On the API management page, click on the "Create API Key" button. <br/>
                            - You will be prompted to fill in some details such as the API name and permissions (e.g.,
                            read-only, trade).
                          </p>

                          <p>
                            4. Set Permissions and Whitelist IP: 
                          <div style={{
                            color: '#67E1AE',
                            border: '1px solid',
                            borderRadius: '10px',
                            padding: '6px 15px',
                            cursor: 'pointer',
                            marginLeft: '10px',
                            display: 'inline-block'
                          }} onClick={copyIP}>COPY OUR IP</div> <br/>
                            - Choose the permissions you want to grant to this API key (Choose these two options
                            read-only and trading).
                          </p>

                          <p>
                            5. Generate and Save Your API Key: <br/>
                            - Click on the "Confirm" button to generate your API key. <br/>
                            - You will receive your API key and secret. Make sure to save these in a secure location as
                            you won't be able to see the secret again.
                          </p>

                          <p>
                            By following these steps, you will successfully create and manage API keys in the OKX crypto
                            exchange. If you encounter any issues or need further assistance, refer to the OKX support
                            documentation or contact their customer support.
                          </p>
                          <p style={{marginTop: '10px', fontWeight: 'bold'}}>
                          Pay attention: You should copy and enter the IP-addresses without any changes. If you do change, API Key will not work properly
                          </p>
                          <div className="text-center mt-4">
                            <button className="themeBtnHome" onClick={() => setPage(3)}>Continue</button>
                          </div>
                        </div>
                      : <></>}

                      {(selectedExchange === 'bitget') ?  
                        <div className="mt-3 connectExMain row" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                          <p>
                          To generate API keys in the Bitget crypto exchange, follow these steps:
                          </p>
                          <p>
                            1. Log In to Bitget: <br/>
                            - Visit the Bitget website and log in to your account.
                          </p>
                          <p>
                            2. Navigate to API Management:<br/>
                            - Once logged in, go to your account dashboard.<br/>
                            - Click on your profile icon or user menu at the top right corner.<br/>
                            - Select "API Management" from the dropdown menu. This will take you to the API
                            management page.
                          </p>
                          <p>
                            3. Create a New API Key:<br/>
                            - On the API management page, click on the "Create API Key" button.<br/>
                            - You will be prompted to fill in some details such as the API name and permissions (e.g.,
                            read-only, trade)
                          </p>
                          <p>
                            4. Set Permissions and Whitelist IP: <div style={{
                            color: '#67E1AE',
                            border: '1px solid',
                            borderRadius: '10px',
                            padding: '6px 15px',
                            cursor: 'pointer',
                            marginLeft: '10px',
                            display: 'inline-block'
                          }} onClick={copyIP}>COPY OUR IP</div>  <br/>
                            - Choose the permissions you want to grant to this API key (Choose these two options
                            read-only and trading).
                          </p>
                          <p>
                            5. Generate and Save Your API Key:<br/>
                            - Click on the "Confirm" button to generate your API key.<br/>
                            - You will receive your API key and secret. Make sure to save these in a secure location as
                            you won't be able to see the secret again.
                          </p>
                          <p>
                            By following these steps, you will successfully create and manage API keys in the Bitget crypto
                            exchange. If you encounter any issues or need further assistance, refer to the Bitget support
                            documentation or contact their customer support.
                          </p>

                          <p style={{marginTop: '10px', fontWeight: 'bold'}}>
                          Pay attention: You should copy and enter the IP-addresses without any changes. If you do change, API Key will not work properly
                          </p>
                          <div className="text-center mt-4">
                            <button className="themeBtnHome" onClick={() => setPage(3)}>Continue</button>
                          </div>
                        </div>
                      : <></>}

                    </div>) : (<></>)
                  }

                  {Page === 3 ?
                  <div className="connectExPage">
                    <div className="head jc-sb ai-center">
                      <h2>Connect exchange</h2>
                      <div className='backIco' onClick={()=>setPage(2)}>
                        <i className="fas fa-arrow-circle-left theme-color cursor-pointer" style={{'fontSize':20}} ></i>
                      </div>
                    </div>
                    <div className="descMain my-4">
                      {selectedExchange === 'binance' ? <img src={binanceYellow} height={"50px"} width={"100px"} alt="" /> : <></>}
                      {selectedExchange === 'okex' ? <img src={okx} height={"50px"} width={"100px"} alt="" /> : <></>}
                      {selectedExchange === 'bitget' ? <img src={bitget} height={"50px"} width={"100px"} alt="" /> : <></>}
                    </div>
                    <div className="form mt-3">
                      <div className="form-group">
                        <label>Name:</label>
                        <div className=''>
                          <input type="text" id="exch-name" className='form-control' />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>API Key:</label>
                        <div className=''>
                          <input type="text" id="exch-key" className='form-control' />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>API Secret:</label>
                        <div className=''>
                          <input type="text" id="exch-secret" className='form-control' />
                        </div>
                      </div>
                      {(selectedExchange === 'okex' || selectedExchange === 'bitget') ? <div className="form-group">
                        <label>Passphrase:</label>
                        <input type="text" id="exch-passphrase" className='form-control' />
                      </div> : (<></>)}
                      <div className="text-center mt-4">
                        <button className="themeBtnHome" onClick={continueToExchangeData}>Connect</button>
                      </div>
                    </div>
                  </div> : (<></>)
                  }
                  {Page === 4 ?
                    <div className="exchangeSupportPage">
                      <div className="head">
                        <h2>Supported Exchanges</h2>
                      </div>
                      <div className="connectOpBtn1">
                        <div className="bBtn active">
                            Spot
                        </div>
                        <div className="bBtn ">
                            Futures
                        </div>
                        <div className="bBtn ">
                            Margin
                        </div>
                        <div className="bBtn ">
                            Options
                        </div>
                      </div>
                      <div className="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    <div className="">
                                      <div><span>Exchange</span></div>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="">
                                      <div><span>Order Types</span></div>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="">
                                      <div><span>Smart Trade</span></div>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="">
                                      <div><span>DCA Bot</span></div>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="">
                                      <div><span>Grid Bot</span></div>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="">
                                      <div><span>Options Bot</span></div>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="">
                                      <div><span>Volume (24h)</span></div>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="">
                                      <div><span>Trading Pairs</span></div>
                                    </div>
                                  </th>
                                  <th scope="col">
                                    <div className="">
                                      <div><span>Connect</span></div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td scope="row">
                                    <div>
                                      <div className="innerDiv">
                                        <div className="exIco"><img src={kuCoin} alt="" /></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <div className="orderTypeMain">
                                          <div className="orderBtn">Spot</div>
                                          <div className="orderBtn">Futures</div>
                                          <div className="orderBtn">Margin</div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <img src={tick} alt="" className="tick" />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <img src={tick} alt="" className="tick" />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <img src={tick} alt="" className="tick" />
                                      </div>
                                    </div>
                                  </td>
                                  
                                  <td>
                                    <div>
                                      <div className="innerDiv"></div>
                                    </div>
                                  </td>
                                  <td>
                                  <div>
                                      <div className="innerDiv">
                                        <div className="volume">$62.35B</div>
                                      </div>
                                  </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <div className="volume">1782</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <div className="connectBtn">Connect</div>
                                       {/* <div className="btnRightCut w-100">
                                        <img className='' src={rightCutBtn} alt="" />
                                        <span>Connect</span>
                                      </div> */}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    <div>
                                      <div className="innerDiv">
                                        <div className="exIco"><img src={kuCoin} alt="" /></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <div className="orderTypeMain">
                                          <div className="orderBtn">Spot</div>
                                          <div className="orderBtn">Futures</div>
                                          <div className="orderBtn">Margin</div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <img src={tick} alt="" className="tick" />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <img src={tick} alt="" className="tick" />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <img src={tick} alt="" className="tick" />
                                      </div>
                                    </div>
                                  </td>
                                  
                                  <td>
                                    <div>
                                      <div className="innerDiv"></div>
                                    </div>
                                  </td>
                                  <td>
                                  <div>
                                      <div className="innerDiv">
                                        <div className="volume">$62.35B</div>
                                      </div>
                                  </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <div className="volume">1782</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <div className="connectBtn">Connect</div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    <div>
                                      <div className="innerDiv">
                                        <div className="exIco"><img src={kuCoin} alt="" /></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <div className="orderTypeMain">
                                          <div className="orderBtn">Spot</div>
                                          <div className="orderBtn">Futures</div>
                                          <div className="orderBtn">Margin</div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <img src={tick} alt="" className="tick" />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <img src={tick} alt="" className="tick" />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <img src={tick} alt="" className="tick" />
                                      </div>
                                    </div>
                                  </td>
                                  
                                  <td>
                                    <div>
                                      <div className="innerDiv"></div>
                                    </div>
                                  </td>
                                  <td>
                                  <div>
                                      <div className="innerDiv">
                                        <div className="volume">$62.35B</div>
                                      </div>
                                  </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <div className="volume">1782</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <div className="innerDiv">
                                        <div className="connectBtn">Connect</div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                            
                              
                              </tbody>
                            </table>
                      </div>
                    </div> : <></>
                  }
                  </>)}
                </div>
              </div>
              
              {/* diver status */}
              {Page != 4 &&
              <div className="col-xl-3 col-lg-4 mb-4 pr-0 pl-xs-0">
                  <RightSidebar />
              </div>
              }
          </div>
      </div>
      {/* /.container-fluid */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          {/* <img src={modalBorder} alt="" className="modalBorder" /> */}

          <div className="connectModal pt-0">
            <img className='closeIco' src={close} alt="" onClick={handleClose} />
            <h2>Connect an exchange</h2>
            <div className="pt-2 pb-4">
              <div className="hrLine"></div>
            </div>
            <div className="desc">Tenup will not have access to transfer or withdraw your assets. Each exchange connects with encrypted API keys</div>
            <div className="optionsMain">
              <div className={selectedTrading === 'spot'?"opBtn active":"opBtn"}  onClick={() => setSelectedTrading(selectedTrading === 'spot' ? '' : 'spot')} >Spot</div>
              <div className="opBtn" >Futures <small>(Coming Soon)</small></div>
            </div>
            <div className="hr2px my-3"></div>
            <div className="exchangeMain">
              <div className="opBtn"><img src={kuCoin} alt="" style={{opacity: 0.1}} /></div>
              <div className="opBtn" onClick={() => setSelectedExchange(selectedExchange === 'bitget' ? '' : 'bitget')} style={{background: selectedExchange === 'bitget' ? 'rgba(242, 242, 242, 0.3)' : ''}}><img src={bitget} alt="" /></div>
              <div className="opBtn" onClick={() => setSelectedExchange(selectedExchange === 'okex' ? '' : 'okex')} style={{background: selectedExchange === 'okex' ? 'rgba(242, 242, 242, 0.3)' : ''}}><img src={okx} alt=""  /></div>
              <div className="opBtn" onClick={() => setSelectedExchange(selectedExchange === 'binance' ? '' : 'binance')} style={{background: selectedExchange === 'binance' ? 'rgba(242, 242, 242, 0.3)' : ''}}><img src={binance} alt="" /></div>
            </div>
            <div className="continueBtn text-center">
              <button className="themeBtnHome" onClick={()=>continueToExchange()}>Continue</button>
            </div>
          {/* </div> */}
            {/* <div className="btnRightCut " onClick={()=>continueToExchange()}>
              <img className='' src={rightCutBtn} alt=""/>
              <span>Continue</span>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
      <KycModal show={kycForm} closeModal={closeKYCForm} 
      name={authedUser.authedUser.kycName} 
      country={authedUser.authedUser.kycCountry} 
      idType={authedUser.authedUser.kycIdType}
      file={authedUser.authedUser.kycFile} 
      faceFile={authedUser.authedUser.kycFaceFile} 
      selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
      kycFileChange={kycFileChange} kycFaceFileChange={kycFaceFileChange} submitKYC={submitKYC}
      />
      {/* KYC MODAL FOR ADMIN VIEW */}
      <KycModal show={kycFormAdmin} closeModal={closeKYCFormAdmin} 
      name={kycUserAdmin.kycName} 
      country={kycUserAdmin.kycCountry} 
      idType={kycUserAdmin.kycIdType}
      file={kycUserAdmin.kycFile} 
      faceFile={kycUserAdmin.kycFaceFile}
      selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
      isAdmin={true} submitKYC={verifyUserKYC} uid={kycUserAdmin._id}
      />
      {/* Update API Modal */}
      <Modal className='' show={showApiModal} onHide={handleCloseAPI} centered>
        <Modal.Body>
        {/* <img src={modalBorder} alt="" className="modalBorder" /> */}

          <div className="otpModalMain editApiModal">
            <div className="closeIcon" onClick={handleCloseAPI}>
              <img src={close} alt="" />
            </div>
            <h2 className="title">Edit API</h2>
            <div className="pb-3">
              <div className="hrLine"></div>
            </div>
            <div className="editApiM">
              <div className="my-3 ">
                  <label>Name</label><br/>
                  <div className="">
                    <input defaultValue={""} id="update-api-key-name" type="text" />
                  </div>
              </div>
              <div className="my-3">
                  <label>Secret Key</label><br/>
                  <div className="">
                    <input defaultValue={""} id="update-api-key-secret" type="text" />
                  </div>
              </div>
              <div className="my-3">
                  <label>API Key</label><br/>
                  <div className="">
                    <input defaultValue={""} id="update-api-key" type="text" />
                  </div>
              </div>
            </div>
            <div className="btnMain mt-4">
               
                <button
                type="submit"
                className="themeBtnHome "
                onClick={() => updateApiKey(balances.connectedExchanges[0]._id)}
                >
                Save
                </button>
                <button
                type="submit"
                className="themeBtnHomeOutline ml-4" 
                onClick={handleCloseAPI}
                >
                Cancel
                </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {Dashboard};
