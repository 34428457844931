import React from 'react'
import closeIco from "../../images/close.svg"
import Modal from "react-bootstrap/Modal";

export const ConditionModal = ({ show, handleClose, agreeFunc }) => {
  return (
    <Modal className='conditionModal' show={show} onHide={handleClose} centered>
    <Modal.Body>
      <div className="otpModalMain">
        <div className="closeIcon" onClick={handleClose}>
          <img src={closeIco} alt="" />
        </div>
        <h2 className="title">Copy Trading Conditions</h2>
        <div className="conditionMain">
            <h3>Your Agreement</h3>
            <p>As a follower of TENUP COPY TRADE, it is important that you carefully read and understand the following copy trade conditions. By utilizing our platform, you agree to the terms set forth below:</p>
            <p>1. To utilize TENUP COPY TRADE, you must be 18 years of age or older, possess full legal capacity and capability, and have a comprehensive understanding of the risks and nature of financial concepts and products. It is imperative that you possess sufficient knowledge and experience in this field to warrant utilizing our services.</p>
            <p>2. You confirm that you are the legal owner of the funds deposited within the connected exchanges. It is important that you maintain full responsibility and control over your account and the funds contained therein.</p>
            <p>3. TENUP COPY TRADE allows for automatic trading operations, and as such, transactions may begin and end without your manual intervention. With this feature, it is important to note that you may experience some delays or unsuccessful trades due to system failures or high costs incurred therein.</p>
            <p>4. It is important to understand that the copy trade wing provided is for informational purposes only. Any investment decisions made based upon information obtained through TENUP COPY TRADE is done at the full discretion and risk of the investor. Tenup and its affiliates cannot be held responsible for any resulting gains or losses that may arise as a result.</p>
            <p>5. We strongly advise investors to make their own investment decisions and conduct thorough research before carrying out any trades. Investors should consider their own investment objectives, personal circumstances, and financial status when making these decisions. While our platform provides valuable insights and assistance, it is ultimately the responsibility of the investor to determine whether a specific investment, strategy, or product meets their needs.</p>
            <p>6. As a copy trading platform, "TENUP COPY TRADE" bears no liability for any lost profits or losses that may result from investment operations performed or not performed by an investor. The use of our platform and reliance on our copy trading function is entirely at the investor's own risk. While we take all possible measures to ensure the accuracy and reliability of our information and services, we cannot guarantee the outcome of any trades made through our platform.</p>
            <p>7. Our website is designed to provide investors with valuable trading dynamics and information services. However, we strongly advise investors to conduct independent research on any information collected through our platform or through copy trading, before making any investment decisions. Investors are urged to exercise caution and critical thinking when using our platform or engaging in copy trading activities.</p>
            <p>8. Lastly, you should be aware that the copy trade system is subject to potential data errors, system failures, and other unforeseen circumstances. While our team works diligently to minimize risk and ensure data accuracy, the aforementioned issues may still occur from time to time.</p>
        </div>
        <div className="btnMain mt-4">
            <button
            type="submit"
            className="themeBtn "
            onClick={agreeFunc}
            >
            Agree
            </button>
            <button
            type="submit"
            className="outlineBtn ml-4" 
            onClick={handleClose}
            >
            Cancel
            </button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
  )
}
