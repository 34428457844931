import React, { useEffect, useState } from 'react'

import btc from "../../../images/btc.svg";
import usdt from "../../../images/usdt.svg";
import ETH from "../../../images/eth.webp";
import usd from "../../../images/usd.svg";
import { useSelector } from 'react-redux';
import { showBalance } from '../../../helpers';

export const RightSidebar = () => {
  const balances = useSelector(state => state.balances);
  const authedUser = useSelector(state => state.authedUser);
  const [ethBalance, setEthBalance] = useState(0);
  const [USDBalance, setUSDBalance] = useState(0);
  const [BTCBalance, setBTCBalance] = useState(0);
  useEffect(() => {
    balances.balances.forEach(b => {
      if (b.asset === 'USDT'){
        setUSDBalance(parseFloat(b.free).toFixed(4).toString());
      }
      if (b.asset === 'ETH'){
        setEthBalance(parseFloat(b.free).toFixed(4).toString());        
      }
      if (b.asset === 'BTC'){
        setBTCBalance(parseFloat(b.free).toFixed(4).toString());
      }
    })
  }, [balances.balances]);

  if (authedUser?.authedUser?.admin){
    return(<></>);
  }
  else{
    
    return (
      <div className="mb-4 themeCardGlobal rightSidebar">
        {/* Card Header - Dropdown */}

        {/* Card Body */}
        <div className="position-relative br-10 border-transparent">

        <div className="card-body p-0">
            <div className=" px-4 py-3  d-flex ai-center jc-sb">
              <h6 className="m-0 diver-name">Your Balance</h6>
              <h6 className='mb-0 diver-name'>${(showBalance(balances.total.toFixed(2), authedUser.authedUser.balanceHide))}</h6>
              {/* <div>
                  <span className="driver-status-title">--</span>
              </div> */}
            </div>
            <div className="">
              <div className="hrLine"></div>
            </div>
            {/* driver card row */}
            <CurrencyRow profileImg={btc} driverName="Bitcoin" company="BTC Wallet" status={showBalance(BTCBalance, authedUser.authedUser.balanceHide)} />
            <div className="">
              <div className="hrLine"></div>
            </div>
            <CurrencyRow profileImg={usdt} driverName="USDT" company="Tether Wallet" status={showBalance(USDBalance, authedUser.authedUser.balanceHide)} />
            <div className="">
              <div className="hrLine"></div>
            </div>
            <CurrencyRow profileImg={ETH} driverName="ETH" company="Dollar Wallet" status={showBalance(ethBalance, authedUser.authedUser.balanceHide)} />
        </div>
        </div>
        {/* <div className="depositBtnMain">
            <button className="depositBtn">Deposit</button>
            <button className="veiwBtn">View</button>
        </div> */}
      </div>
    )
  }
}

function CurrencyRow(props) {
    return(
        <div className="d-flex px-4 py-3 flex-row align-items-center justify-content-between">
          <div className="d-flex ai-center">
            <img className="driver-img rounded-circle" src={props.profileImg} />
              <div className="profile-div ml-2">
                <span className="mr-2 profile-name">{props.driverName}</span>
                {/* <span className="status fs-10  gray-color">{props.company}</span> */}
              </div>
          </div>
          <div>
            <span className="driver-status-title ">{props.status}</span>
          </div>
       </div>
    )
    
  }
  
