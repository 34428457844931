import React, { useRef, useState, useEffect } from 'react'
import { createChart, ColorType, CrosshairMode } from 'lightweight-charts';

import './placeOrder.css'
import profitIco from "../../images/profitIco.svg"
import rightCutBtn from "../../images/rightCutBtn.svg"
import priceUp from "../../images/priceUp.svg"
import signInBorder from "../../images/webp/connectBorder.webp"
import inputBorder from "../../images/inputBorder.svg"
import greenBubble from '../../images/webp/greenBubble.webp'
import redBubble from '../../images/webp/redBubble.webp'
import axios from 'axios';
import { chartData } from './chartData';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { Autocomplete, Paper, TextField } from '@mui/material';

let sellClassesR1 = ["large", "small", "small down100px", "small down80px"], sellClassesR2 = ["medium down40px", "medium", "medium down40px"];
let buyClassesR1 = ["medium down40px", "large down50px", "medium", "small down50px"], buyClassesR2 = ["medium down30px", "medium down40px", "medium"];


export const PlaceOrder = ({ chartPrice, orderBook, orderCategory, setOrderCategory, orderType, setOrderType, symbols, setTradingPiar, tradingPair, rate, price, setPrice, setBuyingAmount, setSellingAmount, sellingAmount, buyingAmount, buyingBalance, sellingBalance, placeOrder }) => {
  const balances = useSelector(state => state.balances);
    
    const [symbol,setSymbol]=useState('BTCUSDT')
    const [startTime,setStartTime]=useState(new Date().getTime() - (24 * 60 * 60 * 1000))
    const [endTime,setEndTime]=useState((new Date()).getTime())
    const [limit,setLimit]=useState(500)
    const [interval,setInterval]=useState('5m')

    useEffect(()=>{
        if(tradingPair){
            if (tradingPair.indexOf('-') > -1) {
                setSymbol(tradingPair.replace('-',''))
            }else{
                setSymbol(tradingPair)
            }
        }
    },[tradingPair])
    return (
    <>
        <div className="exchangeSupportPage placeOrderPage">
            {balances?.connectedExchanges?.length > 0 ?
            <>
            <div className="head">
                <h2>Place Order</h2>
            </div>
            {/* new design */}
            <div className="row mt-2 ai-center">
                <div className="col-xl-6 col-xxl-7">
                 
                    {/* chart */}
                    <ChartComponent
                     symbol={symbol.toUpperCase()}
                     />
                </div>
                <div className="col-xl-6 col-xxl-5">
                    <div className="placeOrderForm themeCardGlobal">
                        
                        <div className="innerForm">
                            <div className='mb-2'>Trade Type:</div>
                            <div className="placeOrderBtnM mb-3 ">
                                <div style={orderType === "BUY" ? {color: '#111', cursor: 'pointer'} : {cursor: 'pointer'}} onClick={()=>{setOrderType("BUY");setSellingAmount(0);setBuyingAmount(0);}} className={orderType === "BUY"?"innerBtn active":"innerBtn"} >BUY</div>
                                <div style={orderType === "SELL" ? {backgroundColor: '#f1493f', color: '#111', cursor: 'pointer'} : {cursor: 'pointer'}} onClick={()=>{setOrderType("SELL");setSellingAmount(0);setBuyingAmount(0);}} className={orderType === "SELL"?"innerBtn active":"innerBtn"} >SELL</div>
                            </div>
                            <div className='mb-2'>Order Type:</div>
                            <div className="placeOrderBtnM mb-3 ">
                                <div className={orderCategory === "MARKET"?"innerBtn active":"innerBtn"}  style={orderCategory === "MARKET" ? {color: '#111', cursor: 'pointer'} : {cursor: 'pointer'}} onClick={()=>{setOrderCategory("MARKET");setSellingAmount(0);setBuyingAmount(0);}}>MARKET</div>
                                <div className={orderCategory === "LIMIT"?"innerBtn active":"innerBtn"}  style={orderCategory === "LIMIT" ? {color: '#111', cursor: 'pointer'} : {cursor: 'pointer'}} onClick={()=>{setOrderCategory("LIMIT");setSellingAmount(0);setBuyingAmount(0);}}>LIMIT</div>
                            </div>

                            <div className="inpuRow">
                                <div className='inputMain'>
                                    <label>Select Pair</label>
                                    <div className="form-group">
                                        <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        style={{padding:0}}
                                        options={symbols}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params}  />}
                                        placeholder='Pair'
                                        onChange={(e,v) => setTradingPiar(v)}
                                        value={tradingPair}
                                        PaperComponent={({ children }) => (
                                            <Paper style={{ background: "#111525",color:"#fff" }}>{children}</Paper>
                                          )}
                                        />
                                    </div>
                                </div>
                                <div className='inputMain'>
                                    <label>Enter Price</label>
                                    <div className="form-group">
                                        <input id="register-name" className="form-control" value={orderCategory === 'MARKET' ? 'Market Price' : price} disabled={orderCategory === 'MARKET'} onChange={e => {
                                            setPrice(e.target.value);
                                            setBuyingAmount(sellingAmount/parseFloat(e.target.value));
                                        }} type="text" placeholder='Enter Price' />
                                         {/* <small>{tradingPair?.split('-')[1]} for 1 {tradingPair?.split('-')[0]} </small> */}
                                    </div>
                                </div>
                            </div>
                            <div className="rate">Rate: 1 {tradingPair?.split('-')[0]} = {rate} {tradingPair?.split('-')[1]}</div>
                            <div className="inpuRow">
                                {/* {orderCategory === 'MARKET' && orderType === 'SELL' ? (<></>) : ( */}
                                    <div className="inputMain">
                                        <label>Enter Total {tradingPair?.split('-')[1]}:</label>
                                        <div className="form-group inputDiv" style={{ display: 'flex'}}>
                                            <input type="number" value={sellingAmount} onChange={e => {
                                                setSellingAmount(parseFloat(e.target.value));
                                                setBuyingAmount(parseFloat(e.target.value)/parseFloat(price))
                                            }}  placeholder='Enter Price' />
                                            <p className='maxText' onClick={() => {
                                                setSellingAmount(parseFloat(sellingBalance));
                                                setBuyingAmount(parseFloat(sellingBalance)/parseFloat(price))
                                            }} style={orderType === 'SELL' ? {display: 'none'} : {
                                                position: 'absolute', right: '13px'
                                            }}>MAX</p>
                                        </div>
                                    </div>
                                {/* )} */}
                                {/* {orderCategory === 'MARKET' && orderType === 'BUY' ? (<></>) : ( */}
                                    <div className="inputMain">
                                        <label>Enter  {tradingPair?.split('-')[0]} Amount</label>
                                        <div className="form-group inputDiv" style={{ display: 'flex'}}>
                                            <input type="number" value={buyingAmount} onChange={e => {
                                                setBuyingAmount(parseFloat(e.target.value));
                                                setSellingAmount(parseFloat(e.target.value)*parseFloat(price));
                                            }}  placeholder='Enter Price' />
                                            <p className='maxText' onClick={() => {
                                                setBuyingAmount(parseFloat(buyingBalance));
                                                setSellingAmount(parseFloat(buyingBalance)*parseFloat(price));
                                            }} style={orderType === 'BUY' ? {display: 'none'} : {
                                                position: 'absolute', right: '10px'
                                            }}>MAX</p>
                                        </div>
                                    </div>
                                {/* )} */}
                            </div>
                            <div className="available">Available: {orderType === 'BUY' ? sellingBalance : buyingBalance} {orderType === 'BUY' ? tradingPair?.split('-')[1] : tradingPair?.split('-')[0]}</div>
                            <div className="text-center">
                                <button onClick={placeOrder} className="themeBtn">Place Order</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-5">
                          {/* table */}
                        <div className="orderHisPage themeCardGlobal my-4 orderList">
                            <div className="table-responsive">
                                <table class="table">
                                        <thead>
                                            <tr>
                                            <th scope="col">
                                                <div className="">
                                                    <div className=''><span>Price({tradingPair?.split('-')[1]})</span></div>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="">
                                                    <div className=''><span>Size({tradingPair?.split('-')[0]})</span></div>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="">
                                                    <div className=''><span>Total({tradingPair?.split('-')[1]})</span></div>
                                                </div>
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderBook.asks.slice(0,7).map((val,key)=>(
                                                <tr key={key}>
                                                    <td scope="row">
                                                        <div>
                                                        <div className="innerDiv">
                                                            <div className="volume text-uppercase text-danger">{val[0]}</div>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td scope="row">
                                                        <div>
                                                        <div className="innerDiv">
                                                            <div className="volume text-uppercase">{val[1]}</div>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td scope="row">
                                                        <div>
                                                        <div className="innerDiv">
                                                            <div className="volume text-uppercase">{(parseFloat(val[0]) * (parseFloat(val[1]))).toFixed(3) || 0}</div>
                                                        </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                </table>
                            </div>
                        </div>
                </div>
                <div className="col-lg-2">
                    <div className="currentPNewM">
                        <div className="title">Current Price</div>
                        <div className="amount">
                            <span>{chartPrice}</span>
                            <img className='ico ml-2' src={priceUp} alt="" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-5">
                          {/* table */}
                          <div className="orderHisPage themeCardGlobal my-4 orderList">
                                    <div className="table-responsive">
                                        <table class="table">
                                                <thead>
                                                    <tr>
                                                    <th scope="col">
                                                        <div className="">
                                                            <div className=''><span>Price({tradingPair?.split('-')[1]})</span></div>
                                                        </div>
                                                    </th>
                                                    <th scope="col">
                                                        <div className="">
                                                            <div className=''><span>Size({tradingPair?.split('-')[0]})</span></div>
                                                        </div>
                                                    </th>
                                                    <th scope="col">
                                                        <div className="">
                                                            <div className=''><span>Total({tradingPair?.split('-')[1]})</span></div>
                                                        </div>
                                                    </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {orderBook.bids.slice(0,7).map((val,key)=>(
                                                        <tr>
                                                        <td scope="row">
                                                            <div>
                                                            <div className="innerDiv">
                                                                <div className="volume text-uppercase text-success">{val[0]}</div>
                                                            </div>
                                                            </div>
                                                        </td>
                                                        <td scope="row">
                                                            <div>
                                                            <div className="innerDiv">
                                                                <div className="volume text-uppercase">{val[1]}</div>
                                                            </div>
                                                            </div>
                                                        </td>
                                                        <td scope="row">
                                                            <div>
                                                            <div className="innerDiv">
                                                                <div className="volume text-uppercase">{(parseFloat(val[0]) * (parseFloat(val[1]))).toFixed(3) || 0}</div>
                                                            </div>
                                                            </div>
                                                        </td>
                                                    
                                                        </tr>
                                                ))}
                                                </tbody>
                                        </table>
                                    </div>
                                     
                                </div>
                </div>

                <div className="col-12">
                <div className="profileBannerMain themeCardGlobal">
                    
                    <div className="orHistoryMain">
                                    <div className="sell">
                                        <div className="row1"> 
                                            {[].concat(
                                                orderBook.asks.slice(0,1),
                                                orderBook.asks.slice(4,7)                                                
                                            ).map((b, i) => {
                                                return(
                                                    <div className={sellClassesR1[i]} key={`${i}-${sellClassesR1[i]}`} >
                                                        <div>
                                                            <img src={redBubble} alt="" />
                                                        <p>Price({tradingPair?.split('-')[1]})</p>
                                                        <p>{parseFloat(b[0]).toFixed(2)}</p>
                                                        <p>Size({tradingPair?.split('-')[1]})</p>
                                                        <p>{(parseFloat(b[1])*parseFloat(chartPrice)).toFixed(3)}</p>
                                                        </div>
                                                    </div>    
                                                );
                                            })}
                                            {/* <div className="small" >
                                                <div>
                                                    <img src={redBubble} alt="" />
                                                <p>Price(USDT)</p>
                                                <p>47586.24</p>
                                                <p>Size(USDT)</p>
                                                <p>17,177.26</p>
                                                </div>
                                            </div>
                                            <div className="small down100px" >
                                                <div>
                                                    <img src={redBubble} alt="" />
                                                <p>Price(USDT)</p>
                                                <p>47586.24</p>
                                                <p>Size(USDT)</p>
                                                <p>17,177.26</p>
                                                </div>
                                            </div>
                                            <div className="large" >
                                                <div>
                                                    <img src={redBubble} alt="" />
                                                <p>Price(USDT)</p>
                                                <p>47586.24</p>
                                                <p>Size(USDT)</p>
                                                <p>17,177.26</p>
                                                </div>
                                            </div>
                                            <div className="small down80px" >
                                                <div>
                                                    <img src={redBubble} alt="" />
                                                <p>Price(USDT)</p>
                                                <p>47586.24</p>
                                                <p>Size(USDT)</p>
                                                <p>17,177.26</p>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="row2">
                                            {[].concat(
                                                orderBook.asks.slice(1,4)
                                            ).map((b, i) => {
                                                return(
                                                    <div className={sellClassesR2[i]} key={`${i}-${sellClassesR2[i]}`} >
                                                        <div>
                                                            <img src={redBubble} alt="" />
                                                        <p>Price({tradingPair?.split('-')[1]})</p>
                                                        <p>{parseFloat(b[0].substring(0,7))}</p>
                                                        <p>Size({tradingPair?.split('-')[1]})</p>
                                                        <p>{(parseFloat(b[1])*parseFloat(chartPrice)).toFixed(3)}</p>
                                                        </div>
                                                    </div>    
                                                );
                                            })}
                                            {/* <div className="medium down40px" >
                                                <div>
                                                    <img src={redBubble} alt="" />
                                                <p>Price(USDT)</p>
                                                <p>47586.24</p>
                                                <p>Size(USDT)</p>
                                                <p>17,177.26</p>
                                                </div>
                                            </div>
                                            <div className="medium" >
                                                <div>
                                                    <img src={redBubble} alt="" />
                                                <p>Price(USDT)</p>
                                                <p>47586.24</p>
                                                <p>Size(USDT)</p>
                                                <p>17,177.26</p>
                                                </div>
                                            </div>
                                            <div className="medium down40px" >
                                                <div>
                                                    <img src={redBubble} alt="" />
                                                <p>Price(USDT)</p>
                                                <p>47586.24</p>
                                                <p>Size(USDT)</p>
                                                <p>17,177.26</p>
                                                </div>
                                            </div> */}

                                        </div>

                                    </div>
                                    <div className="buy">
                                    <div className="row1">
                                            {[].concat(
                                                orderBook.bids.slice(1,2),
                                                orderBook.bids.slice(0,1),
                                                orderBook.bids.slice(2,3),
                                                orderBook.bids.slice(6,7)
                                            ).map((b, i) => {
                                                return(
                                                <div className={buyClassesR1[i]} >
                                                    <div>
                                                        <img src={greenBubble} alt="" />
                                                    <p>Price({tradingPair?.split('-')[1]})</p>
                                                    <p>{parseFloat(b[0].substring(0,7))}</p>
                                                    <p>Size({tradingPair?.split('-')[1]})</p>
                                                    <p>{(parseFloat(b[1])*parseFloat(chartPrice)).toFixed(3)}</p>
                                                    </div>
                                                </div>
                                                );
                                            })}
                                        </div>
                                        <div className="row2">
                                            {[].concat(
                                                orderBook.bids.slice(3,6)
                                            ).map((b, i) => {
                                                return(
                                                <div className={buyClassesR2[i]} >
                                                    <div>
                                                        <img src={greenBubble} alt="" />
                                                    <p>Price({tradingPair?.split('-')[1]})</p>
                                                    <p>{parseFloat(b[0].substring(0,7))}</p>
                                                    <p>Size({tradingPair?.split('-')[1]})</p>
                                                    <p>{(parseFloat(b[1])*parseFloat(chartPrice)).toFixed(3)}</p>
                                                    </div>
                                                </div>
                                                );
                                            })}

                                            {/* <div className="medium down30px" >
                                                <div>
                                                    <img src={greenBubble} alt="" />
                                                <p>Price(USDT)</p>
                                                <p>47586.24</p>
                                                <p>Size(USDT)</p>
                                                <p>17,177.26</p>
                                                </div>
                                            </div>
                                            <div className="medium down40px" >
                                                <div>
                                                    <img src={greenBubble} alt="" />
                                                <p>Price(USDT)</p>
                                                <p>47586.24</p>
                                                <p>Size(USDT)</p>
                                                <p>17,177.26</p>
                                                </div>
                                            </div>
                                            <div className="medium" >
                                                <div>
                                                    <img src={greenBubble} alt="" />
                                                <p>Price(USDT)</p>
                                                <p>47586.24</p>
                                                <p>Size(USDT)</p>
                                                <p>17,177.26</p>
                                                </div>
                                            </div> */}

                                        </div>

                                    </div>

                    </div>
                       
                </div>
                </div>
            </div>
            </>
            :
            <div className='verticalCenterNoData'>
                <h2 className='text-center'>Please connect your exchange before placing your order.</h2>
            </div>
            }
        </div>
    </>
  )
}

let tvScriptLoadingPromise;

export default function ChartComponent({symbol}) {
  const onLoadScriptRef = useRef();

  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_dbed6') && 'TradingView' in window) {
          new window.TradingView.widget({
            autosize: true,
            symbol: symbol?`BINANCE:${symbol}`:`BINANCE:BTCUSDT`,
            interval: "D",
            timezone: "Etc/UTC",
            theme: "dark",
            style: "1",
            locale: "en",
            enable_publishing: false,
            allow_symbol_change: true,
            container_id: "tradingview_dbed6"
          });
        }
      }
    },
    [symbol]
  );

  return (
    <div className='tradingview-widget-container mb-4'>
      <div id='tradingview_dbed6' />
      <div className="tradingview-widget-copyright">
        {/* <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a> */}
      </div>
    </div>
  );
}