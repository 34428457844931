import React,{useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';


function SendMailToAll() {
  const [value, setValue] = useState('');
  const [subject, setSubject] = useState('');
  const [loader,setLoader]=useState(false)

   const handleSubmit = async (paidUsers) => {
    try {
      if(value && subject){
        setLoader(true)
        let payload ={
          template: value,
          subject: subject,
        }
        if (paidUsers){
          payload.paid = 'yes';
        }
        // const userData = await api.sendMailToAllUser(payload)
        let userData = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/send-mail-to-all`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }).then(r => r.json())
        
        if(userData.success){
          toast(userData.message, {theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT})
          setLoader(false)
        }else{
          toast("Something went wrong, email not sent ", {theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT})
          setLoader(false)
        }
        console.log(value);
      }else{
        setLoader(false)
        toast("Please insert fields", {theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT});
      }
    } catch (error) {
      setLoader(false)
      console.log(error);
      toast("Something went wrong, email not sent ", {theme: 'dark', position: toast.POSITION.BOTTOM_RIGHT})
    }
   }

    var Editor = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
  

    return(
      <>
                  {/* Begin Page Content */}
                    <div className="container-fluid"style={{minHeight: '100vh'}}>
                     {/* Content Row */}
                      <div className="row">
                      {/* Area Chart */}
                      <div className="col">
                         <div className="row mx-0 drivers-row">
                             <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                                 <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Send Mail To All User</h4>
                              
                             </div>
                         </div>
                         <div className="card sortby-box br-10 border-transparent mt-3"> 
                            <div className="card-header br-10  bg-white border-transparent py-3 ">
                              <div className="form-group mb-3">
                                <input type="text" className='form-control' onChange={(e)=>setSubject(e.target.value)} placeholder='Subject...' />
                              </div>
                                <ReactQuill placeholder='Add Email description' modules={Editor} theme="snow" value={value} onChange={setValue} />
                                <div className="sendMailBtn mt-3">
                                    <button onClick={() => handleSubmit(false)} className='btn-dark'>Send Mail to all</button>
                                </div>
                                <div className="sendMailBtn mt-3">
                                    <button onClick={() => handleSubmit(true)} className='btn-dark'>Send Mail to Paid Users</button>
                                </div>
                            </div>
                         </div>
                         {/* table */}

                      </div>
                      
                    </div>
              
                  </div>
                  {/* /.container-fluid */}
                  {loader &&
                    <div className="seekoLoader">
                        <div className="loader">
                            {/* <img src={Logo} alt="loader" /> */}
                        </div>
                    </div>
                }
      </>
    )
}

export default SendMailToAll