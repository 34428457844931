import React from 'react';
import "./landingPage.css";
import { Navbar } from './navbar';
import banner from '../../images/homeBanner.png'
import aboutImage from '../../images/aboutImage.png'
import wunderImage from '../../images/wunderImage.png'
import videoPoster from '../../images/videoPoster.png'
import kuCoin from '../../images/kuCoin.svg'
import bitget from '../../images/bitget.svg'
import okx from '../../images/okx.svg'
import binanceIco from '../../images/binanceIco.svg'
import easyIco from '../../images/easyIco.png'
import transparentIco from '../../images/transparentIco.png'
import safeIco from '../../images/safeIco.png'

import binanceIco1 from '../../images/binanceIco.png'
import bybitIco from '../../images/bybitIco.png'
import okxIco from '../../images/okxIco.png'
import bitmexIco from '../../images/bitmexIco.png'
import kucoinIco from '../../images/kucoinIco.png'
import BtcIco from '../../images/BtcIco.png'
import { Footer } from './footer';
import { Link } from 'react-router-dom';

export const Privacy = () => {
  return (
    <>
    <div className="landingPage">
        <Navbar />
        <section className='sec1'>
            <div className="container">
                <div className="row ai-center">
                    <div className="col-lg-12">
                      <h4 className='text-center'>Privacy Policy for TENUP Copy Trading Platform</h4>
                      <p>Last updated: 16.05.2024</p>
                      <h5><strong>
                        Introduction
                      </strong></h5>
                      <p>
                        Welcome to the TENUP Copy Trading Platform, accessible via https://copytrade.tenup.io/.
                        We are committed to protecting your privacy and ensuring that your personal information is
                        handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use,
                        protect, and disclose information and data when you use the TENUP Copy Trading Platform.
                        
                      </p>
                      <h5><strong>
                        Information Collection
                      </strong></h5>
                      <p>
                        We collect information that you provide directly to us when you use our services. This may
                        include:
                        <ul>
                          <li>
                            **Contact information**: Your name and email address.
                          </li>
                          <li>
                            **Usage information**: Details of how you use our services, including trade settings and
                            preferences.
                          </li>
                        </ul>
                      </p>
                      <p>
                      We also automatically collect certain information when you visit our website:
                      <ul>
                        <li>
                          **Cookies and tracking technologies**: We use cookies and other tracking technologies to
                          collect information about your browsing activities and preferences. We use this information to
                          enhance your user experience and improve the quality of our website and services.
                        </li>
                        <li>
                          **Analytics**: We use analytics tools to collect information about your interaction with our
                          website, including pages visited, time spent on pages, and other traffic data. This information
                          helps us to understand usage patterns and to improve our website.
                        </li>
                      </ul>
                      </p>

                      <h5><strong>
                        Use of Information
                      </strong></h5>

                      <p>
                        The information we collect is used in the following ways:
                        <ul>
                          <li>
                            To provide and maintain our services, including to enable you to make use of our copy trading
                            features.
                          </li>
                          <li>
                            To improve and personalize your experience on our platform.
                          </li>
                          <li>
                            To communicate with you, including sending you marketing and promotional emails, subject to
                            your subscription preferences.
                          </li>
                          <li>
                            To monitor and analyze trends, usage, and activities in connection with our website and
                            services.
                          </li>
                        </ul>
                      </p>

                      <h5><strong>
                      Information Sharing and Disclosure
                      </strong></h5>

                      <p>

                      TENUP does not share your personal information with third parties, except as described in this
                      policy. We may share your information with:
                      <ul>
                        <li>
                          Service providers: Entities that perform services on our behalf, including hosting, data
                          analysis, information technology, customer service, email delivery, and auditing services. These
                          service providers are authorized to use your personal information only as necessary to provide
                          these services to us.
                        </li>
                        <li>
                          Legal requirements: If required by law or if we believe that such action is necessary to comply
                          with legal processes, protect and defend our rights or property, and/or to protect the personal
                          safety of users or the public.
                        </li>
                      </ul>
                      </p>

                      <h5><strong>
                        Non-Custodial Policy
                      </strong></h5>

                      <p>
                      TENUP operates on a non-custodial basis. This means that we do not store or have access to
                      your wallet assets. You retain full control over your wallet and the assets within it.
                      </p>
                      
                      <h5><strong>
                        Non-Custodial Policy
                      </strong></h5>
                      Cookies and Analytics
                      Our platform uses cookies and other tracking technologies to collect information about your
                      activities and preferences. You can set your browser to reject cookies, but this may limit your
                      use of certain features or functions on our website.
                      
                      <h5><strong>
                        Your Rights and Choices
                      </strong></h5>
                      <p>
                      You may update, correct, or delete your account information at any time by logging into your
                      account and modifying your information. You can also opt out of receiving promotional emails
                      from us by following the unsubscribe link provided in those communications.
                      </p>                      
                      <h5><strong>
                      Changes to This Privacy Policy
                      </strong></h5>
                      <p>
                      We may update this Privacy Policy from time to time. We will notify you of any changes by
                      posting the new Privacy Policy on this page. We encourage you to review this Privacy Policy
                      periodically for any changes.
                      </p>
                      
                      <h5><strong>
                        Contact Us
                      </strong></h5>
                      <p>
                      If you have any questions about this Privacy Policy, please contact us at contact@tenup.io
                      By using our services, you consent to the collection, use, and sharing of your information as
                      described in this Privacy Policy.
                      </p>                      

                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
    </>
  )
}
