// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tradersDiaryPage {
    margin-top: 70px;
    padding-left: 30px;
    padding-right: 15px;
    color: #fff;
}
.tradersDiaryPage h2{
    text-align: center;

}`, "",{"version":3,"sources":["webpack://./src/component/tradersDiary/tradersDiary.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,kBAAkB;;AAEtB","sourcesContent":[".tradersDiaryPage {\n    margin-top: 70px;\n    padding-left: 30px;\n    padding-right: 15px;\n    color: #fff;\n}\n.tradersDiaryPage h2{\n    text-align: center;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
