import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../portfolio/portfolio.css"
import { setBalances, setTotalUSDBalance } from "../../redux/ActionCreators";
import AssetsTable from "./assetsTable";

export default function MyAssets(){
  const dispatch = useDispatch();
  const authedUser = useSelector(s => s.authedUser);

  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL+"/users/balances", {
      method: 'GET',
      headers : {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(r => r.json()).then(res => {
      dispatch(setBalances(res.balances));
      dispatch(setTotalUSDBalance(res.total));
    })
  }, []);

  return(
    <div className="container-fluid">
    {/* Content Row */}
      <div className="row">
        <div className="col-12 mb-4" >
          <div className="portfolioPage">
            {!authedUser?.authedUser?.master ? (
              (authedUser?.authedUser?.kycVerified && authedUser?.authedUser?.approved) ? (
                authedUser?.authedUser?.paid ? (
                    <>
                      <div className="header">
                        <h2>My Assets</h2>
                      </div>
                      <AssetsTable />
                    </>
                ) : (
                  <div className="notApproveYet">
                    <p>Dear Trader, </p>
                    <p>Please pay the subscription fees 3000 TENUP in order to start using the services of this platform.</p>
                    <p>Best regards,</p>
                    <p>The Tenup Copy Trading Team.</p>
                  </div>                  
                )
              ) : (
                <div className="notApproveYet">
                  <p>Dear Trader, </p>
                  <p>Please wait for the KYC to be approved in order to start using the services of this platform.</p>
                  <p>Best regards,</p>
                  <p>The Tenup Copy Trading Team.</p>
                </div>

              )
            ) : (
              <>
              <div className="header">
                <h2>My Assets</h2>
              </div>
              <AssetsTable />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}