import React from 'react'
import logo from '../../images/logo.svg'
import {Link} from 'react-router-dom'
export const Navbar = () => {
  return (
    
    <nav className="navbar navbar-expand-lg ">
        <div className="container">

            <a className="navbar-brand" href="#">
                <img src={logo} alt="" />
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            {/* <span className="navbar-toggler-icon" /> */}
            <i className="fas fa-bars color-white"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="#feature">FEATURES</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#exchanges">EXCHANGES</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#how-work">HOW IT WORKS?</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://tenup.io/" target='_blank'>TENUP</a>
                </li>
            </ul>
            <Link to={'/login'} className="themeBtnHome my-2 my-sm-0" >Sign In</Link>
            </div>
        </div>
    </nav>
  )
}
