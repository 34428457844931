import TenupIcon from "../../images/logoMini.png"
import { RotatingLines } from  'react-loader-spinner'

export function Loader({loader, text}) {

    return(
        <>
            {loader &&
                <div className="seekoLoader">
                    <div className="loading">
                    <RotatingLines
                        strokeColor="#67E1AE"
                        strokeWidth="5"
                        animationDuration="1"
                        width="96"
                        visible={true}
                        />
                    {text ? <p>{text}</p> : ''}
                    </div>
                </div>
            }
        </>
    )
}