import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setOrders } from '../../redux/ActionCreators';
import Modal from 'react-bootstrap/Modal';

import yellowBubble from '../../images/yellowball.svg'
import greenBubble from '../../images/webp/greenBubble.webp'
import redBubble from '../../images/webp/redBubble.webp'
import modalBorder from "../../images/webp/modalBorder.webp";
import close from "../../images/close.svg";
import longBorder from "../../images/webp/longBorder.webp";

import './ongoingTransaction.css'
import { toast } from 'react-toastify';

const getOrders = async (uid, token) => {
  return fetch(process.env.REACT_APP_BASE_URL+"/users/get-orders-user/", {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${token}`
      }
  }).then(r => r.json())
}

const OngoingSec = () => {
  const orders = useSelector(state => state.orders);
  const authedUser = useSelector(state => state.authedUser);

  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (o) => {setModalOrder(o); setShow(true)};
  const [modalOrder, setModalOrder] = useState({});

  const dispatch = useDispatch();
  const cancelOrCloseOrder = (order) => {
    if (!loader){
      setLoader(true);
      if (authedUser.authedUser.master){
        fetch(`${process.env.REACT_APP_BASE_URL}/orders/close-trade`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({orderId: order?.orderId})
        }).then(r => r.json())
        .then(res => {
          if (res.success){
            toast('Success', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            let ord_updated = false;
            let new_o = orders.orders.map(o => {
              if (o.orderId === res.order.orderId){
                ord_updated = true;
                return res.order;
              }
              else{
                return o;
              }
            });
            if (ord_updated){dispatch(setOrders(new_o))}
            else{dispatch(setOrders(orders.orders.concat([res.order])))}
          }
          else{
            toast(res.msg, {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
          }
          handleClose();
          setModalOrder(false);
          setLoader(false);
        })
      }
      else{
        fetch(`${process.env.REACT_APP_BASE_URL}/orders/close-trade-individual`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({orderId: order?.orderId})
        }).then(r => r.json())
        .then(res => {
          if (res.success){
            toast('Success', {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            let ord_updated = false;
            let new_o = orders.orders.map(o => {
              if (o.orderId === res.order.orderId){
                ord_updated = true;
                return res.order;
              }
              else{
                return o;
              }
            });
            if (ord_updated){dispatch(setOrders(new_o))}
            else{dispatch(setOrders(orders.orders.concat([res.order])))}
          }
          else{
            toast(res.msg, {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: 'dark'
            });
            if (res.order){
              let ord_updated = false;
              let new_o = orders.orders.map(o => {
                if (o.orderId === res.order.orderId){
                  ord_updated = true;
                  return res.order;
                }
                else{
                  return o;
                }
              });
              if (ord_updated){dispatch(setOrders(new_o))}
              else{dispatch(setOrders(orders.orders.concat([res.order])))}
            }
          }
          handleClose();
          setModalOrder(false);
          setLoader(false);
        })
      }  
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getOrders(authedUser.authedUser._id, localStorage.getItem('token')).then(r => {
        if (r.success){
          dispatch(setOrders(r.orders))
        }
      })
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, [orders.orders]);


  return(
    <div className="exchangeSupportPage orderHisPage">
    <div className="head">
      <h2>Open Positions</h2>
    </div>
    {/* new design */}
    <div className="ongoingTransactionMain">
      <div className="col2">
        {orders.orders
        .filter(order => {
          if (order?.side !== 'SELL' && !order?.closed){
            if (order?.side === 'BUY' ){
              if (order?.SLTPOrder && !authedUser.authedUser.master){
                return false;
              }
              else{
                return true;
              }
            }
            else{
              return true;
            }
          }
          else{
            if (order?.type === 'STOP_LOSS_LIMIT' && !order?.closed){
              return true;
            }
            else{
              return false;
            }
          }
        })
        .map(order => {
          return (
            <div key={`ong-${order._id}`} style={{cursor: 'pointer'}} onClick={() => handleShow(order)} 
            className={(order?.status === 'FILLED' && order?.type === "STOP_LOSS_LIMIT") ? ((parseFloat(order?.entryPrice) || order?.status === 'FILLED') ? (parseFloat(order?.entryPrice ? order?.entryPrice : parseFloat(order?.cummulativeQuoteQty)/parseFloat(order?.origQty)) <= parseFloat(order?.marketPrice) ? "greenBubbleMain" : 'redBubbleMain') : "greenBubbleMain") : "yellowBubbleMain"}>
              <div>
                <img src={order?.status === 'FILLED' ? ((parseFloat(order?.entryPrice) || order?.status === 'FILLED') ? (parseFloat(order?.entryPrice ? order?.entryPrice : parseFloat(order?.cummulativeQuoteQty)/parseFloat(order?.origQty)) <= parseFloat(order?.marketPrice) ? greenBubble : redBubble) : greenBubble) : yellowBubble} alt="" />
                {/* <div className="long">{((parseFloat(order?.entryPrice) || order?.status === 'FILLED') ? (parseFloat(order?.entryPrice ? order?.entryPrice : parseFloat(order?.cummulativeQuoteQty)/parseFloat(order?.origQty)) > parseFloat(order?.marketPrice) ? "Short" : 'Long') : "Short")}</div> */}
                <div className="roi">
                  {parseFloat(order?.entryPrice) || order?.status === 'FILLED' 
                      ? parseFloat((Math.abs(parseFloat(order?.entryPrice ? order?.entryPrice : parseFloat(order?.cummulativeQuoteQty)/parseFloat(order?.origQty)) - parseFloat(order?.marketPrice))/parseFloat(order?.entryPrice ? order?.entryPrice : parseFloat(order?.cummulativeQuoteQty)/parseFloat(order?.origQty))*100).toFixed(2)) 
                      : 0}%
                </div>
                <div className="pair">{order?.symbol} - {order?.type === "STOP_LOSS_LIMIT" ? orders.orders.filter(o => o._id === order?.masterOrderId)[0]?.orderCategory : order?.type}</div>
                <div className="pair">Status: {order?.status === 'NEW' ? 'Pending' : 'Filled'}</div>
                <div className="pair">{order?.status === 'NEW' ? parseFloat((parseFloat(order?.origQty)*order?.price).toFixed(2)) : parseFloat((parseFloat(order?.origQty)*order?.marketPrice).toFixed(2))}$</div>
                <div className="pair">{parseFloat(order?.price).toFixed(3)} {order?.symbol?.substring(3)}</div>
              </div>
            </div>  
          );
        })}
      </div>
    </div>
    <Modal  show={show} onHide={handleClose} centered>
      <Modal.Body>
        {/* <img src={longBorder} alt="" className="modalBorder" /> */}

        <div className="connectModal ongoingModal">
          <img className='closeIco' src={close} alt="" onClick={handleClose} />
          <div className="row1" style={{justifyContent: 'space-between'}}>
            <div className="pair">{modalOrder?.origQty} - {modalOrder?.symbol}</div>
            {/* <div className="balanceBtn w-100">
                <div className="innerBtn">
                  {((parseFloat(modalOrder?.entryPrice) || modalOrder?.status === 'FILLED') ? (parseFloat(modalOrder?.entryPrice ? modalOrder?.entryPrice : parseFloat(modalOrder?.cummulativeQuoteQty)/parseFloat(modalOrder?.origQty)) > parseFloat(modalOrder?.marketPrice) ? "SHORT" : 'LONG') : "SHORT")}
                </div>
            </div> */}
            <div className="pair"><span>PNL: </span>{((parseFloat(modalOrder?.entryPrice) || modalOrder?.status === 'FILLED') ? (parseFloat(modalOrder?.entryPrice ? modalOrder?.entryPrice : parseFloat(modalOrder?.cummulativeQuoteQty)/parseFloat(modalOrder?.origQty)) > parseFloat(modalOrder?.marketPrice) ? "-" : '+') : "")}{parseFloat(modalOrder?.entryPrice) || modalOrder?.status === 'FILLED' 
              ? parseFloat((Math.abs(parseFloat(modalOrder?.entryPrice ? modalOrder?.entryPrice : parseFloat(modalOrder?.cummulativeQuoteQty)/parseFloat(modalOrder?.origQty)) - parseFloat(modalOrder?.marketPrice))/parseFloat(modalOrder?.entryPrice ? modalOrder?.entryPrice : parseFloat(modalOrder?.cummulativeQuoteQty)/parseFloat(modalOrder?.origQty))*100).toFixed(2)) 
              : 0}%</div>
          </div>
          <div className="row2">
            <div className="col1">
              <div className="key">
                <p>Order ID</p>
                <p>Quantity</p>
                <p>Market price</p>
                <p>Entry price</p>
              </div>
              <div className="val">
                <p style={{fontWeight: '700'}}>{modalOrder?.orderId}</p>
                <p style={{fontWeight: '700'}}>{parseFloat(modalOrder?.origQty)} {modalOrder?.symbol?.includes('-') ? modalOrder?.symbol?.split('-')[0] : modalOrder?.symbol?.substring(0,3)} - {parseFloat((parseFloat(modalOrder?.origQty)*modalOrder?.marketPrice)).toFixed(4)}$</p>
                <p style={{fontWeight: '700'}}>{modalOrder?.marketPrice}</p>
                <p style={{fontWeight: '700'}}>{(modalOrder?.type === 'STOP_LOSS_LIMIT' ? modalOrder?.entryPrice : (modalOrder?.status === 'FILLED' ? (parseFloat(modalOrder?.cummulativeQuoteQty)/parseFloat(modalOrder?.origQty)).toFixed(2) : '-'))}</p>
              </div>
            </div>
            <div className="col1">
              <div className="key">
              {authedUser?.authedUser?.master ? <></> : <p>Position TP/SL</p>}
                <p>Date</p>
                <p>Type</p>
                <p>Status</p>
              </div>
              <div className="val">
                {authedUser?.authedUser?.master ? <></> : <p style={{fontWeight: '700'}}>{(modalOrder?.status === 'FILLED' || modalOrder?.type === 'LIMIT') ? '-' : parseFloat(modalOrder?.price)}/{parseFloat(modalOrder?.stopPrice) ? parseFloat(modalOrder?.stopPrice) : '-'}</p>}
                <p style={{fontWeight: '700'}}>{new Date(modalOrder?.time).getHours() > 9 ? '' : '0'}{new Date(modalOrder?.time).getHours()}:{new Date(modalOrder?.time).getMinutes() > 9 ? '' : '0'}{new Date(modalOrder?.time).getMinutes()} - {new Date(modalOrder?.time).getDate()}/{new Date(modalOrder?.time).getMonth()+1}/{new Date(modalOrder?.time).getFullYear().toString().substring(2)}</p>
                <p style={{fontWeight: '700'}}>
                  {modalOrder?.type === "STOP_LOSS_LIMIT" ? orders.orders.filter(o => o._id === modalOrder?.masterOrderId)[0]?.orderCategory : modalOrder?.type}
                </p>
                <p style={{fontWeight: '700'}}>
                  <>
                  {modalOrder?.type !== 'STOP_LOSS_LIMIT' ? (
                    <>
                      {modalOrder?.status === 'FILLED' ? 'Filled' : <></>}
                      {modalOrder?.status === 'NEW' ? 'Pending' : <></>}
                      {modalOrder?.status === 'CANCELLED' ? 'Cancelled' : <></>}
                    </>
                  ) : (<>Filled</>)}
                  </>
                </p>
              </div>
            </div>
          </div>
          <div className="row1" style={{justifyContent: 'center'}}>
            <div className="themeBtn dib ">
              <div onClick={() => cancelOrCloseOrder(modalOrder)} className="innerBtn">
                {loader ? <div className='loader-btn mx-4'></div> : "Close Trade"}
              </div>
            </div>
          </div>

        </div>
      </Modal.Body>
    </Modal>

  </div>
  );
}

export const UserCopyTradeOngoing = () => {
  const authedUser = useSelector(state => state.authedUser);
  
  return (
    <div className="container-fluid">
    {/* Content Row */}
    <div className="row">
      {/* <div className={Page == 1?"col-12":"col-xl-9 col-lg-8 mb-4"} > */}
      <div className={"col-12"} >
        <div className="dashboardMain">
          {authedUser.authedUser.master ? (
            <OngoingSec />
            ) : (
            (authedUser?.authedUser?.kycVerified && authedUser?.authedUser?.approved) ? (
              (authedUser?.authedUser?.paid) ? (
                <OngoingSec />
              ) : (
                <div className="notApproveYet">
                  <p>Dear Trader, </p>
                  <p>Please pay the subscription fees 3000 TENUP in order to start using the services of this platform.</p>
                  <p>Best regards,</p>
                  <p>The Tenup Copy Trading Team.</p>
                </div>                      
              )
            ) : (
              <div className="notApproveYet">
                <p>Dear Trader, </p>
                <p>Please wait for the KYC to be approved in order to start using the services of this platform.</p>
                <p>Best regards,</p>
                <p>The Tenup Copy Trading Team.</p>
              </div>
            )
          )}
        </div>
      </div>
  </div>
  {/* /.container-fluid */}
</div>
  )
}
