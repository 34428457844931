import * as ActionType from './ActionType';

export const loginUser = (u) => ({
  type: ActionType.LOGGED_IN,
  payload: u
});

export const updateUser = (u) => ({
  type: ActionType.UPDATE_USER,
  payload: u
});

export const logoutUser = () => ({
  type: ActionType.LOGGED_OUT,
});

export const setBalances = (b) => ({
  type: ActionType.SET_BALANCES,
  payload: b
});

export const setTotalUSDBalance = (b) => ({
  type: ActionType.SET_USD_BALANCE,
  payload: b
});

export const setOrders = (b) => ({
  type: ActionType.SET_ORDERS,
  payload: b
});

export const setLoaderAction = (b) => ({
  type: ActionType.SET_LOADER,
  payload: b
});

export const setConnectedExchange = (b) => ({
  type: ActionType.SET_CONNECTED_EXCHANGE,
  payload: b
});