import * as ActionType from '../ActionType';

export const Orders = (state = {
  isLoading: false,
  orders: []
}, action) => {
  switch(action.type){
    case ActionType.SET_ORDERS:
      return {...state, orders: action.payload};
    case ActionType.LOGGED_OUT:
      return {...state, orders: []};
    default:
      return state;
  }
}