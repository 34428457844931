import * as ActionType from '../ActionType';

export const Loaders = (state = {
  isLoading: false,
  loader: false
}, action) => {
  switch(action.type){
    case ActionType.SET_LOADER:
      return {...state, loader: action.payload};
    default:
      return state;
  }
}