import React, { useEffect, useState } from 'react';
import { QnAModal } from '../QnAModal';
import { useSelector } from 'react-redux';

const limit = 10;
export default function MasterApplications({usrs, getUsers, approveUser, setKycUserAdmin, openKYCFormAdmin}){
  
  let [masterApplications, setMasterApplications] = useState([]);
  let [currApplication, setCurrApplication] = useState({});
  let [qnaModal, setQnAModal] = useState(false);
  let authedUser = useSelector(s => s.authedUser);

  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL+"/admin/applications", {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authedUser.authedUser.token}`,
      }
    }).then(r => {
      if (r.ok){
        return r.json();
      }
      else{
        return {success: false, msg: 'Failed'};
      }
    }).then(res => {
      if (res.success){
        setMasterApplications(res.applications);
      }
      else{
        toast(res.msg ? res.msg : 'Failed to fetch', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        });
      }
    })
  }, []);

  return(
    <>
      <div className='mt-3'>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <div style={{marginLeft: '10px'}}>
              <button onClick={() => getUsers(localStorage.getItem('token'))}>Refresh Applications</button>
            </div>
          </div>

          <div>
            <span>Total Applications: {masterApplications.length} </span>
          </div>
        </div>
        <table style={{width: '100%'}}>
          <thead>
            <th>Full Name</th>
            <th>Email</th>
            <th>Application Details</th>
            <th>Action</th>
          </thead>
          <tbody>
            {masterApplications.map(u => {
              return(
                <tr style={{border: '1px solid grey'}} key={`admin-${u._id}`}>
                  <td style={{ padding: '10px' }}>{u.qna1_name}</td>
                  <td style={{ padding: '10px' }}>{u.qna1_email}</td>
                  <td onClick={() => {
                      setCurrApplication(u);
                      setQnAModal(true);
                  }} style={u.kycFile ? {textDecoration : 'underline', cursor: 'pointer', padding: '10px' } : {padding: '10px'}}>View</td>
                  <td>
                    <button>Reject</button>
                    <button>Approve</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <QnAModal 
      show={qnaModal}
      data={currApplication}
      admin={true}
      handleClose={() => setQnAModal(false)}
      />
    </>
  );
}

