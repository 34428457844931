import React, { useState } from 'react'
import "./settings.css"
import personalIco from "../../images/personalIco.svg";
import secureIco from "../../images/secureIco.svg";
import billingIco from "../../images/billingIco.svg";
import Switch from '@mui/material/Switch';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const Settings = () => {
    const authedUser = useSelector(state => state.authedUser);
    const [loader, setLoader] = useState(false);
    const [payments, setPayments] = useState([]);

    const [is2FA, setIs2FA] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [show, setShow] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2FA, setShow2FA] = useState(false);

    const handleShow2FA = () => setShow2FA(true);
    const handleClose2FA = () => setShow2FA(false);

    const forgetPass = (body) => {
        const url = `${process.env.REACT_APP_BASE_URL}/users/forget`;
        return axios.post(url, body);
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoader(true);

            const payload = {
            email: authedUser.authedUser?.email,
            };
            const response = await forgetPass(payload);
            
            if (response?.data?.message == "Please verify your mail first") {
                return toast(response?.data?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    theme: 'dark'
                });
            
            }
            if (response?.data?.message == "incorrect email address") {
            return toast(response?.data?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
            });
            }
            if (response?.data?.error) {
                return toast("Something went wrong", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    theme: 'dark'
                });
            }
            if (response?.data?.message == "Email sent") {
                handleShow()
                setTimeout(() => {
                    handleClose()
                }, 3000);
            }
            setLoader(false);
        } catch (e) {
            setLoader(false);
            console.log('====================================');
            console.log(e);
            console.log('====================================');
            toast("Something went wrong", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
            });
        }
    };

    const handleEnable2FA = async(e) => {
        const payload = {
            id: authedUser.authedUser?._id,
            val: e.target.checked
          };

        setLoader(true)
        const url = `${process.env.REACT_APP_BASE_URL}/users/enable2fa`;
        let response = await fetch(url,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${localStorage.getItem('token')}`
            },
        }).then(r => r.json())
        // let response = await axios.post(url, payload);
        
        if(response.success){
            if(response.message == "2FA Enabled"){
                setIs2FA(true)
            }else{
                setIs2FA(false)
            }

            handleShow2FA()
            setTimeout(() => {
                handleClose2FA()
            }, 3000);
            setLoader(false)

        }else{
            toast("Error on changing 2FA setting", {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: 'dark'
            })
            setLoader(false)
            return
        }
    }
    const getPaymentHistory = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/users/payment`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(r => r.json())
        .then(r => {
            if (r.success){
                setPayments(r.payments);
            }
        })
    };
    useEffect(() => {
        if (authedUser?.authedUser?._id){
            getPaymentHistory();
        }
        if(authedUser?.authedUser?.enable2FA) {
            setIs2FA(authedUser?.authedUser?.enable2FA)
        }
    }, [authedUser.authedUser]);

    const cancelSub = () => {
        if (Object.keys(authedUser?.authedUser)){
            fetch(`${process.env.REACT_APP_BASE_URL}/users/cancel-sub`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(r => r.json())
            .then(res => {
                if (res.success){
                    toast("Successfully cancelled", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        theme: 'dark'
                    });
                }
                else{
                    toast(res.msg);
                }
            })
        }
        setCancelModal(false);
    }

    return (
        <div className="container-fluid">
        {/* Content Row */}
            <div className="row">
            <div className="col-12 mb-4" >
                <div className="copyTradePage settingsPage">
                    <div className="header">
                        <h2>Settings</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="settingCardMain">
                                <div className="settingCard themeCardGlobal">
                                    <div className="header mb-0">
                                        <h6>Personal Data</h6>
                                        <img className='cardIco' src={personalIco} alt="" />
                                    </div>
                                    <div className="pb-3">
                                        <div className="hrLine"></div>
                                    </div>
                                    <div className="key mb-2">Username: <span>{authedUser?.authedUser?.username}</span></div>
                                    <div className="key mb-2">Email: <span>{authedUser.authedUser?.email}</span></div>
                                    <div className="key mb-2">KYC Status: <span>{authedUser.authedUser?.kycVerified ? 'Verified' : 'Not verified'}</span></div>
                                    <div className="key mb-2">Payment Status: <span>{authedUser.authedUser?.paid ? 'Paid' : 'Not paid'}</span></div>
                                    {/* <button className="themeOutlineBtn my-3">Change email</button> */}
                                    {/* <div className="key mb-3 ">My accounts: <span>01</span></div>
                                    <button className="themeOutlineBtn">Change email</button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="settingCardMain">
                                <div className="settingCard themeCardGlobal">
                                    <div className="header mb-0">
                                        <h6>Security</h6>
                                        <img className='cardIco' src={secureIco} alt="" />
                                    </div>
                                    <div className="pb-3">
                                        <div className="hrLine"></div>
                                    </div>
                                    <div className="key mb-0">Password</div>
                                    <button className="themeOutlineBtn my-3" onClick={onSubmit}>Change password</button>
                                    <div className="switchMain">
                                        <div>
                                            <div className="key mb-1">2 Factor Authentication</div>
                                            <div className="val mb-2">When enabled you will receive a code on email on every login. Recommended for better security.</div>
                                        </div>
                                        <div className="switch">
                                            <Switch {...label} onChange={(e)=>handleEnable2FA(e)} checked={is2FA} />   
                                        </div>
                                    </div>
                                    {/* <div className="switchMain ai-center mb-2">
                                        <div className="key">IP address check</div>
                                        <div className="switch">
                                            <Switch {...label} />   
                                        </div>
                                    </div> */}
                                    {/* <button className="themeOutlineBtn">More details</button> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <div className="settingCardMain">
                                <h6>Personal Data</h6>
                                <div className="settingCard">
                                    <img className='cardIco' src={billingIco} alt="" />
                                    <div className="key mb-2">Subscription type: <span>Free</span></div>
                                    <div className="key">Subscription: <span>Active</span></div>
                                    <button className="themeOutlineBtn my-3">Manage subscriptions</button>
                                    <div className="key mb-3 ">Subsciption History</div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    {/* <div className="mt-5">
                        <div className="row2 themeCardGlobal">
                            <div className="header mb-0">
                                <h6>My Settings</h6>
                            </div>
                            <div className="pb-3">
                                <div className="hrLine"></div>
                            </div>
                            {payments.length > 0 ? (
                            <div className='row'>
                                <div className='col-12'>
                                    <table style={{color: 'white'}} className='table'>
                                        <thead>
                                            <th>Tx Hash</th>
                                            <th>Time</th>
                                            <th>View</th>
                                        </thead>
                                        <tbody>
                                            {payments.map(p => {
                                                return(
                                                    <tr key={p._id}>
                                                        <td>{p.txHash}</td>
                                                        <td>{new Date(parseInt(p.time)*1000).getDate()}/{new Date(parseInt(p.time)*1000).getMonth()+1}/{new Date(parseInt(p.time)*1000).getFullYear()}</td>
                                                        <td><a href={`https://testnet.bscscan.com/tx/${p.txHash}`} target='_blank'>Link to explorer</a></td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            ) : (<></>)}
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className='mb-3'>
                                        <label className="key">Language</label>
                                        <input placeholder='English' type="text" name="" id="" />
                                    </div>
                                    <div className='mb-3'>
                                        <label className="key">Timezone</label>
                                        <input placeholder='(UTC: 01:00) Europe' type="text" name="" id="" />
                                    </div>
                                     <div className='mb-3'>
                                        <label className="key">Refferral code</label>
                                        <div>
                                            <div className="referalCode">
                                                <input placeholder='English' type="text" name="" id="" />
                                                <button className="themeBtn">Apply</button>
                                            </div>
                                        </div>
                                    </div> 

                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className='mb-3'>
                                        <label className="key">Show stats from</label>
                                        <input  type="date" name=""  />
                                    </div>
                                     {authedUser?.authedUser?.paid ? (
                                    <div className='mb-3'>
                                        <label className="key">Cancel Subscription</label><br/>
                                        <button className='themeBtn' onClick={() => setCancelModal(true)}>Cancel</button>
                                    </div>
                                    ) : (<></>)} 

                                     <div className="switchMain">
                                        <div>
                                            <div className="key mb-1">2 Factor Authentication</div>
                                            <div className="val">When enabled you will be able to use Beta features of copytrade,</div>
                                        </div>
                                        <div className="switch">
                                            <Switch {...label} />   
                                        </div>
                                    </div> 
                                </div>
                                 <div className="col-lg-4 col-md-6">
                                    <div className="switchMain ai-center">
                                        <div>
                                            <div className="key">2 Factor Authentication</div>
                                        </div>
                                        <div className="switch">
                                            <Switch {...label} />
                                        </div>
                                    </div>
                                    <div className="switchMain mb-1 ai-center">
                                        <div>
                                            <div className="key">2 Factor Authentication</div>
                                        </div>
                                        <div className="switch">
                                            <Switch {...label} />   
                                        </div>
                                    </div>
                                    <div className="switchMain">
                                        <div>
                                            <div className="key mb-1">2 Factor Authentication</div>
                                            <div className="val">Disabling pop-ups with some information on copytrading</div>
                                        </div>
                                    </div>
                                    <button className="themeBtn reset mt-4">Reset</button>
                                </div> 
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            
            </div>

            <Modal show={show} onHide={handleClose} backdrop="static" centered>
                <Modal.Body>
                <div className="otpModalMain">
                {loader ?
                <h2 className="title">Loading..</h2>
                :
                    <h2 className="title">Please check email, password link sent</h2>
                }
                
                </div>
                </Modal.Body>
            </Modal>

            <Modal show={show2FA} onHide={handleClose2FA} backdrop="static" centered>
                <Modal.Body>
                <div className="otpModalMain">
                {loader ?
                <h2 className="title">Loading..</h2>
                :
                    <h2 className="title">{is2FA?"Enabled 2 Factor Authentication":"Disabled 2 Factor Authentication"}</h2>
                }
                
                </div>
                </Modal.Body>
            </Modal>

            <Modal show={cancelModal} onHide={() => setCancelModal(false)} backdrop="static" centered>
                <Modal.Body>
                <div className="otpModalMain text-center">
                <h2 className="title mb-5">Are you sure you want to cancel your subscription?</h2>
                <button className='themeBtn mr-3' onClick={( ) => setCancelModal(false)}>Close</button>
                <button className='themeBtn' onClick={cancelSub}>Cancel Subscription</button>
                </div>
                </Modal.Body>
            </Modal>
                
        </div>
    )
}
