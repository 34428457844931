import React,{useEffect,useState} from 'react';
import { RightSidebar } from '../global/rightSidebar/rightSidebar';
import "./tradersDiary.css"

function TradersDiary(props) {

  return(
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
            {/* Content Row */}
            <div className="row">
                <div className="col-12 mb-4" >
                    <div className="tradersDiaryPage">
                        <h2>Coming Soon</h2>
                    </div>
                </div>
              
              {/* diver status */}
              {/* <div className="col-xl-3 col-lg-4 mb-4 pr-0">
                  <RightSidebar />
              </div> */}
          </div>
            
      </div>
     
       
    </>
  )
}


export {TradersDiary};
