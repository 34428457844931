import profile from "../../images/profile.jpg";

const driverData=[
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"active",
    },
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"Inactive",
    },
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"active",
    },
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"Inactive",
    },
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"active",
    },
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"Inactive",
    },
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"active",
    },
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"Inactive",
    },
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"active",
    },
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"Inactive",
    },
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"active",
    },
    {
        profile:profile,
        driverName:"Dappomatics Tech",
        driverEmail:"dappomatics@gmail.com",
        companyName:"ABC Trucking, LLC",
        loadMove:"37",
        status:"Inactive",
    },
  
]
export {driverData}