import React, {useEffect, useState } from 'react';
import { BrowserRouter,Redirect, BrowserRouter as Router,Route,Switch } from "react-router-dom";

import  {Dashboard}  from '../component/dashboard/dashboard';
import Drivers from '../component/company/company';
import { Portfolio } from '../component/portfolio/porfolio';
import { CopyTrade } from '../component/copyTrade/copyTrade';
import { FolowTrade } from '../component/copyTrade/followTrader';
import Profile from '../component/profile/profile';
import { UserCopyTrade } from '../component/userCopyTrade/UserCopyTrade';
import { UserCopyTradeOngoing } from '../component/userCopyTrade/UserCopyTradeOngoing';
import MyAssets from '../component/assets/myassets';
import { Settings } from '../component/settings/settings';
import { TradersDiary } from '../component/tradersDiary/tradersDiary';
import { Subscription } from '../component/subscription/subscription';
import { Billing } from '../component/billing';
import { LandingPage } from '../component/landingPage/landingPage';

function AppRouter({validateJWT}){

    return(
        <>
            {/* <Route exact path='/'  >
                <Redirect to="/dashboard" />
            </Route> */}
            <Route exact={true} path="/" component={LandingPage} />
            <Route exact path='/dashboard' component={() => <Dashboard validateJWT={validateJWT} />} />
            <Route path='/Company' component={Drivers} />
            <Route path='/portfolio' component={Portfolio} />
            <Route path='/assets' component={MyAssets} />
            <Route path='/copytrade' component={CopyTrade} />
            <Route path='/profile' component={Profile} />
            <Route path='/followtrader/:traderId' component={FolowTrade} />
            <Route path='/user-history' component={UserCopyTrade} />
            <Route path='/billing' component={Billing} />
            <Route path='/user-ongoing-orders' component={UserCopyTradeOngoing} />
            <Route path='/settings' component={Settings} />
            <Route path='/traders-diary' component={TradersDiary} />
            <Route path='/subscription' component={Subscription} />
        </>
    )
}

export default AppRouter