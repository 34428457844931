import React,{useEffect,useState} from 'react';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import profile from "../../images/profile.jpg";

import { RightSidebar } from '../global/rightSidebar/rightSidebar';
import "./copytrade.css"

import profitIco from "../../images/profitIco.svg"
import lossIco from "../../images/lossIco.svg"
import btc from "../../images/btc.svg";
import Waqar_Zaka from "../../images/Waqar_Zaka.png";
import close from "../../images/close.svg";
import success from "../../images/success.svg";

import { useDispatch, useSelector } from 'react-redux';
import { setOrders } from '../../redux/ActionCreators';
import { PlaceOrder } from '../userCopyTrade/placeOrder';
import { CopyTrader } from './copyTrader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Balancebar } from '../global/balanceBar/balanceBar';
import { toast } from 'react-toastify';

function CopyTrade(props) {
  const [showComplete, setShowComplete] = useState(false);
  const [orderLoading, setOrderLoading] = useState(true);
  const [masters, setMasters] = useState([]);
  const [price, setPrice] = useState(0);
  const [chartPrice, setChartPrice] = useState(0);
  const [rate, setRate] = useState(0);
  const [tradingPair, setTradingPiar] = useState('');
  const [buyingBalance, setBuyingBalance] = useState(0);
  const [sellingBalance, setSellingBalance] = useState(0);
  const [buyingAmount, setBuyingAmount] = useState(0);
  const [sellingAmount, setSellingAmount] = useState(0);
  const [symbols, setSymbols] = useState([]);
  const [orderType, setOrderType] = useState('BUY');
  const [orderCategory, setOrderCategory] = useState("LIMIT");
  const [orderBook, setOrderBook] = useState({bids: [], asks: []});
  const [loading, setLoading] = useState(false);

  const authedUser = useSelector(state => state.authedUser);
  const balances = useSelector(state => state.balances);
  const orders = useSelector(state => state.orders);

  const dispatch = useDispatch();
  const history = useHistory();

  const getOrderBook = (sym) => {
    if (balances.connectedExchanges.length > 0){
      if (balances.connectedExchanges[0].exchange === 'binance'){
        fetch(`${process.env.REACT_APP_BINANCE_BASE_URL}/api/v3/depth?symbol=${sym.split('-').join('')}&limit=7`)
        .then(res => res.json())
        .then(r => {
            setOrderBook({
              bids: r.bids.sort((a,b) => {
                if (a[1] > b[1]) {return -1}
                else {return 1}
              }),
              asks: r.asks.sort((a,b) => {
                if (a[1] > b[1]) {return -1}
                else {return 1}
              })
            });
        });
      }
      else if (balances.connectedExchanges[0].exchange === 'okex'){
        fetch(`https://www.okx.com/api/v5/market/books?instId=${sym}&sz=7`, {
          method: 'GET'
        }).then(r => r.json())
        .then(res => {
          setOrderBook({
            bids: res.data[0].bids.sort((a,b) => {
              if (a[1] > b[1]) {return -1}
              else {return 1}
            }),
            asks: res.data[0].asks.sort((a,b) => {
              if (a[1] > b[1]) {return -1}
              else {return 1}
            })
          });
        })
      }
      else if (balances.connectedExchanges[0].exchange === 'bitget'){
        fetch(`https://api.bitget.com/api/v2/spot/market/orderbook?symbol=${sym.split('-').join('')}&limit=7`)
        .then(r => r.json())
        .then(res => {
          setOrderBook({
            bids: res.data.bids.sort((a,b) => {
              if (a[1] > b[1]) {return -1}
              else {return 1}
            }).slice(0,7),
            asks: res.data.asks.sort((a,b) => {
              if (a[1] > b[1]) {return -1}
              else {return 1}
            }).slice(0,7)
          });
        })
      }
    }
  }

  const handleClose = () => {
    setShowComplete(false);
    history.push("/user-ongoing-orders");
  };
  const handleShow = () => setShowComplete(true);

  const setTraderDetails = (m) => {
    setMasters(masters.map(mast => {
        if (mast._id === m._id){
            return m;
        }
        else{
            return mast;
        }
    }))
  }

  const getMasters = async () => {
    fetch(process.env.REACT_APP_BASE_URL+"/users/masters/all", {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(r => r.json())
    .then(res => {
        if (res.success){
            setMasters(res.users);
        }
    })
  }

  
  const getSymbols = async (exc) => {
    fetch(process.env.REACT_APP_BASE_URL+"/orders/all-symbols"+`/${exc}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(r => r.json())
    .then(res => {
        setSymbols(res.symbols);
        setTradingPiar(res.symbols[0])
    })
  }

  const placeOrder = () => {
    // return;
    if (authedUser.authedUser.master){
      if (!price){
        toast('Price is required', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        }); return;
      }
      if (!buyingAmount){
        toast('Buying amount is required', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        }); return;
      }
      if (!sellingAmount){
        toast('Selling amount is required', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark'
        }); return;
      }
        setOrderLoading(true);
        handleShow();
        fetch(process.env.REACT_APP_BASE_URL+"/users/place-order", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authedUser.authedUser.token}`
            },
            body: JSON.stringify({ 
              tradingPair: tradingPair, 
              price, 
              quantity: buyingAmount, 
              orderType, sellingAmount, orderCategory,
            })
        }).then(r => r.json())
        .then(res => {
            handleClose();
            setOrderLoading(false);
            if (res.success){
                dispatch(setOrders([res.order].concat(orders.orders)));
                handleShow()
            }
            else{
                toast(res.msg, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  theme: 'dark'
                });
            }
        })
    }
  }

  useEffect(() => {
    if (!authedUser.authedUser.master){
        getMasters();
    }
  }, []);

  useEffect(() => {
    if (authedUser.authedUser.master){
      if (balances.connectedExchanges.length > 0){
        getSymbols(balances.connectedExchanges[0].exchange);
      }
    }
  }, [authedUser.authedUser, balances.connectedExchanges]);

  useEffect(() => {
    if (authedUser.authedUser?.master && tradingPair){
      setLoading(true);
      fetch(process.env.REACT_APP_BASE_URL+"/users/ticker/"+tradingPair, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${authedUser.authedUser.token}`
          }
      }).then(r => r.ok ? r.json() : ({success: false}))
      .then(res => {
        setLoading(false);
        if (res.success){
          setPrice(res.price);
          setChartPrice(res.price);
          setRate(res.price);
          setBuyingBalance(res.buyingBalance);
          setSellingBalance(res.sellingBalance);
        }
        else{
          toast('Unable to load info, refresh the page.', {
            theme: 'dark',
            position: toast.POSITION.BOTTOM_RIGHT
          });
        }
      });
        getOrderBook(tradingPair);
    }
  }, [tradingPair, orderType, orderCategory]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (authedUser.authedUser?.master && tradingPair){
        getOrderBook(tradingPair);
        fetch(process.env.REACT_APP_BASE_URL+"/users/ticker/"+tradingPair, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${authedUser.authedUser.token}`
          }
      }).then(r => r.json())
      .then(res => {
          // setPrice(res.price);
          setChartPrice(res.price);
          setRate(res.price);
          setBuyingBalance(res.buyingBalance);
          setSellingBalance(res.sellingBalance);
      });
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [orderBook]);

  return(
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
            {/* <Balancebar /> */}

            {/* Content Row */}
            <div className="row">
              <div className="col-xl-12 mb-4" >
                <div className="copyTradePage mt-0">
                    {/* <div className="header mb-0">
                        <h2 className='mb-0'>Copy Trade</h2>
                    </div> */}
                    {/* <div className="tagLine">Waqar Zaka the Pioneer of Crypto Trading</div> */}
                    {authedUser.authedUser?.master ? (
                      <div>
                        <PlaceOrder orderCategory={orderCategory} setOrderCategory={setOrderCategory} orderType={orderType} setOrderType={setOrderType} 
                        setTradingPiar={setTradingPiar} tradingPair={tradingPair} rate={rate} symbols={symbols}
                        chartPrice={chartPrice} price={price} setPrice={setPrice} setBuyingAmount={setBuyingAmount} orderBook={orderBook}
                        setSellingAmount={setSellingAmount} sellingAmount={sellingAmount} buyingAmount={buyingAmount}
                        buyingBalance={buyingBalance} sellingBalance={sellingBalance} placeOrder={placeOrder}
                        />
                      </div>
                    ) : (
                      (authedUser?.authedUser?.kycVerified && authedUser?.authedUser?.approved) ? (
                        authedUser?.authedUser?.paid ? (
                          <>
                            <div className="header mb-0">
                              <h2 className='mb-0'>Copy Trade</h2>
                            </div>
                            <div className="balanceMain row">
                              {masters.map((master, ind) => {
                                  return(
                                    <CopyTrader setTraderDetails={setTraderDetails} master={master} key={`${ind}-${master._id}`} />
                                  );
                              })}
                            </div>
                          </>
                        ) : (
                          <div className="notApproveYet">
                            <p>Dear Trader, </p>
                            <p>Please pay the subscription fees 3000 TENUP in order to start using the services of this platform.</p>
                            <p>Best regards,</p>
                            <p>The Tenup Copy Trading Team.</p>
                          </div>      
                        )
                      ) : (
                        <div className="notApproveYet">
                          <p>Dear Trader, </p>
                          <p>Please wait for the KYC to be approved in order to start using the services of this platform.</p>
                          <p>Best regards,</p>
                          <p>The Tenup Copy Trading Team.</p>
                        </div>
                      )
                    )}
                </div>
              </div>
              
              {/* diver status */}
              {/* <div className="col-xl-3 col-lg-4 mb-4 pr-0">
                  <RightSidebar />
              </div> */}
          </div>
            
      </div>
     
      <Modal show={showComplete} onHide={orderLoading ? () => {} : handleClose} centered>
        <Modal.Body>
          <div className="connectModal OrderCompelteModal">
            {orderLoading ? (
              <>
                <div className='loader mx-auto'></div>
                <h4 className='mt-3'>Placing your order...</h4>
              </>
            ) : (
              <>
                <img className='closeIco' src={close} alt="" onClick={handleClose} />
                <img className='OrderCompelteImg' src={success} alt="" />
                <h2>Order Completed</h2>
                <p>You order have been successfully placed from binance wallet</p>
                <div className="continueBtn text-center">
                  <button onClick={handleClose} className="themeBtn mt-4" >Confirm</button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {loading ? (
      <Modal show={loading} centered>
        <Modal.Body>
          <div className="connectModal OrderCompelteModal">
            {loading ? (
              <>
                <div className='loader mx-auto'></div>
                <h4 className='mt-3'>Loading...</h4>
              </>
            ) : (
              <>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      ) : (<></>)}
    </>
  )
}

export {CopyTrade};
