import  AppRouter from "../../route/route";
import Sidebar from '../global/sidebar/sidebar';
import Navbar from '../global/navbar/navbar';
import toggleIco from "../../images/toggleIco.svg"
import { useSelector } from 'react-redux';
import { Loader } from "../loader/loader";
import { useEffect, useState } from "react";
import Intercom from '@intercom/messenger-js-sdk';

export const AdminPages =({validateJWT})=> {
  const loader = useSelector(state => state.loaders);
  const [sidebarToggle,setSidebarToggle]= useState(false);
  const authedUser = useSelector(s => s.authedUser);
  useEffect(() => {
    Intercom({
      app_id: 'q0xvrcbh',
      user_id: authedUser.authedUser.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: authedUser.authedUser.username, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: authedUser.authedUser.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      created_at: (new Date(authedUser.authedUser.createdAt).getTime())/1000, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
  }, []);
return(
    <>
        <div id="wrapper">
            <button onClick={()=>setSidebarToggle(!sidebarToggle)}  id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
              <img src={toggleIco} alt="" />
            </button>
            <Sidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
              <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                  <Navbar />
                {/* Begin Page Content */}
                  <AppRouter validateJWT={validateJWT} />
                {/* End of Main Content */}
              </div>
          </div>
          {loader.loader &&
            <Loader loader={loader}  />
            }
        </div>
        {/* Scroll to Top Button*/}
        <a className="scroll-to-top rounded" href="#">
          <i className="fas fa-angle-up" />
        </a>
    </>
)
}