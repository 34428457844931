import React,{useEffect, useState} from 'react';
import { BrowserRouter, BrowserRouter as Router,Redirect,Route,Switch } from "react-router-dom";

import './App.css'
import './component/global/global.css'
import './component/dashboard/dashboard.css'
// import './component/pump/loads.css'
import './component/company/company.css'
import cImg from './images/webp/connectImg.webp'

import $ from 'jquery';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/jquery/dist/jquery.min.js';
import '../node_modules/jquery.easing/jquery.easing';

import '../node_modules/popper.js/dist/popper'

// route
import Main from './main';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';


const store = ConfigureStore();

function App() {
  const [isLogin,setIsLogin]= useState(false)

  useEffect(() => {
    const imagesToBePreloaded = [cImg]
    imagesToBePreloaded.forEach(image => { new Image().src = image })
  }, []);

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Router>
            <Main />
          </Router>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export {App};
